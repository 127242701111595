import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabsInputComponent } from "../../../../../../component/index.jsx"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../../helpers/StringCollection.js"
import swal from "sweetalert"
import {
  createUser,
  editUser,
  getUserDetail
} from "../../../../../../redux/actions/AccountAction.jsx"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { CreateUser } from "./step/CreateUser.jsx"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers/index.js"
import { getListRegionalSelected } from "../../../../../../redux/actions/MasterDataAction.jsx"
import { ILUSTRATION_CONSTANS } from "../../../../../../constants/ImageConstants.js"

export const CreateAccount = ({
  history,
  match: {
    params: { action, id }
  }
}) => {
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState(true)
  const { General, Account } = useSelector((state) => state)
  const subHeader = useSubheader()
  const formRef = useRef()
  const [initialValue, setInitialValue] = useState({
    name: "",
    email: "",
    role: "",
    phone: "",
    province_id: ""
  })
  const [data, setData] = useState("")
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const stepsTitle = [null]
  const createEditFlow = [<CreateUser key={1} setIsValid={setIsValid} isReadOnly={false} />]

  const stepsDescription = [null]

  useEffect(() => {
    if (action === "edit") {
      dispatch(getUserDetail(id))
    }
  }, [])

  useEffect(() => {
    dispatch(getListRegionalSelected())
  }, [])

  useEffect(() => {
    if (Account.data) {
      setData(Account.data)
    }
  }, [Account.data])

  useEffect(() => {
    setInitialValue((prevState) => ({
      ...prevState
    }))
  }, [])
  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isValid])

  useEffect(() => {
    if (Account.userNew) {
      if (action === "edit") {
        setSwalMessage("Data user CMS berhasil diperbarui")
      } else {
        setSwalMessage(
          "user CMS berhasil ditambahkan.\n" +
            "Password dari user " +
            Account.userNew.email +
            ":" +
            "\n \n" +
            Account.userNew.password
        )
      }
    }
  }, [Account.userNew])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        swal({
          icon: swalIcon,
          text: swalMessage
        })
      }
      history.push("/account/list")
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    if (data !== undefined && id) {
      setInitialValue((prevState) => ({
        ...prevState,
        name: data.account_name,
        email: data.account_email,
        role: data.role_id,
        province_id: data.regional_id ? data.regional_id : "ALL",
        phone: data.phone
      }))
    }
  }, [data])

  useEffect(() => {
    subHeader.setTitle(action === "edit" ? "Edit user CMS" : "Tambah user CMS")
    subHeader.setBreadcrumbs(null)
  }, [])

  const ValidationScheme = [
    Yup.object().shape({
      name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama user ", FORM_NEED.harusDiIsi)),
      email: Yup.string()
        .email("Invalid Email")
        .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
      role: Yup.string().required(getStringErrorMassage("role ", FORM_NEED.harusDiIsi)),
      phone: Yup.string()
        .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(15, "Maximal 15 Digit"),
      province_id: Yup.string().required(getStringErrorMassage("Regional", FORM_NEED.harusDiIsi))
    })
  ]

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className="btn btn-primary"
      onClick={() => {
        formRef.current.handleSubmit()
      }}>
      Submit
    </button>
  )

  function onSubmitForm(e) {
    const { values, submited } = e
    if (action === "edit") {
      setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS))
    } else {
      setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.ILLUSTRATION_SUCCESS))
    }
    if (submited) {
      const formData = {
        name: values?.name,
        email: values?.email,
        role: values?.role,
        phone: values?.phone,
        regional_id: values?.province_id !== "ALL" ? values?.province_id : null
      }
      if (id) {
        dispatch(editUser(formData, id))
      } else {
        dispatch(createUser(formData))
      }
    }
  }

  return (
    <>
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="createUser"
        innerRef={formRef}
        componentTab={createEditFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}