import React from "react"
import { Button } from "@material-ui/core"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import { convertDotToComma, toThousandConvert } from "../../../app/helpers/TextHelper.js"
export const CardAreaSertification = ({
  area_id,
  area_code,
  variety_name,
  land_area_meter,
  land_area_ha,
  sub_district_name,
  mitra_name,
  female_planting_date,
  handleSelect = () => {},
  className,
  labelSelect,
  labelColor
}) => {
  const handleClick = () => {
    handleSelect(area_id)
  }
  return (
    <div
      className={`px-4 py-3 rounded-card d-flex justify-content-between align-items-center mb-6 ${
        className ? className : "bg-white"
      }`}>
      <div>
        <div className={"font-size-lg font-weight-bolder"}>{area_code}</div>
        <div className={"d-flex color-gray mb-5 align-items-center"}>
          <div className={"font-italic"}>{variety_name}</div>
          <div className={"circle mx-2"}></div>
          <div className={"font-italic"}>
            {toThousandConvert(land_area_meter)} m<sup>2</sup>{" "}
            <span className={"font-italic ml-2"}>({convertDotToComma(land_area_ha)} Ha)</span>
          </div>
        </div>
        <div className={"d-flex align-items-center mb-2"}>
          <img src={ICON_CONSTANS.IC_LOCATION} alt={""} className={"mr-2"} />
          <div>{sub_district_name}</div>
        </div>
        <div className={"d-flex align-items-center mb-2"}>
          <img src={ICON_CONSTANS.IC_MITRA} alt={""} className={"mr-2"} />
          <div>{mitra_name}</div>
        </div>
        <div className={"d-flex align-items-center mb-2"}>
          <img src={ICON_CONSTANS.IC_CALENDER_GRAY} alt={""} className={"mr-2"} />
          <div>{female_planting_date}</div>
        </div>
      </div>
      <Button
        style={{
          fontSize: "14px",
          textDecoration: "underline"
        }}
        className={"text-capitalize underline font-size-lg"}
        color={labelColor}
        onClick={handleClick}>
        {labelSelect}
      </Button>
    </div>
  )
}
