import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../component/atoms/Tabel/index.jsx"
import {
  EmptyCard,
  NoteView,
  TableSkeleton,
  TableView
} from "../../../../../../component/index.jsx"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { toMeter } from "../../../../../helpers/TextHelper.js"
import {
  ICON_CONSTANS,
  ILLUSTRATION_ASSETS_V2
} from "../../../../../../constants/ImageConstants.js"
import { FloatingSelectArea } from "../../../../../../component/atoms/FloatingSelectArea/index.jsx"
import { activityBulkList } from "../../../../../../redux/actions/AreaAction.jsx"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant.js"
import { linkWithCtrl } from "../../../../../helpers/Utils.js"
import { getUrlParsingPageFilter } from "../../../../../../config/EndpointCollection.js"

export function TabReadySendFinance({ data, changePage, history, general, isFilter }) {
  const subheader = useSubheader()
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  useEffect(() => {
    setCount(selectedRows.length)
  }, [selectedRows])

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.area_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">
          {e?.land_area ? toMeter(e?.land_area) : "-"}
        </span>
      </>
    )
  }
  const uiFarmer = (e) => {
    //using data dummy before endpoint ready
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.farmer_name ? e?.farmer_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.farmer_code ? e?.farmer_code : "-"}
        </span>
      </>
    )
  }
  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e?.mitra_code ?? "-"}</span>
      </>
    )
  }
  const uiPlant = (e) => {
    //using data dummy before endpoint ready
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.plant_type_name ? e?.plant_type_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.variety_name ? e?.variety_name : "-"}
        </span>
      </>
    )
  }
  const uiType = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.activity_name ?? "-"}</p>
      </>
    )
  }

  const uiActivityDate = (e) => {
    const dateConvert = e?.activity_date
      ? timeStampToDate(e?.activity_date, DATE_TYPE_ENUM.ISO_DATE_STRAP)
      : "-"

    return (
      <>
        <p className="text-dark mb-1 font-size-lg">{dateConvert}</p>
      </>
    )
  }
  const uiCreatedDate = (e) => {
    const dateConvert = e?.submitted_date ? timeStampToDate(e?.submitted_date, "dd-MM-yyyy") : "-"
    const TimeConvert = e?.submitted_date ? timeStampToDate(e?.submitted_date, "HH:mm:ss") : "-"

    return (
      <>
        <p className="text-dark mb-1 font-size-lg">
          {dateConvert} <br /> {TimeConvert}
        </p>
      </>
    )
  }

  const linkDetail = (area_id, type, id, e) => {
    const url = RoutesConstants.AREA.DETAIL_ACTIVITY(area_id, type, id, e)
    if (e?.ctrlKey) {
      linkWithCtrl(url)
    } else {
      history.push(url)
    }
  }

  const handleBulkErp = () => {
    if (selectedRows.length) {
      dispatch(activityBulkList(selectedRows))
      history.push(RoutesConstants.AREA.PREVIEW_BULK_ERP())
    }
  }

  const columns = [
    {
      dataField: "plantingSeason.area.name",
      text: t("table_header.area_name"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "plantingSeason.area.farmer.name",
      text: t("table_header.farmer"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      }
    },
    {
      dataField: "plantingSeason.area.farmer.account.mitra.name",
      text: t("table_header.mitra"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "plant_name",
      text: t("table_header.planting_type"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlant
      }
    },
    {
      dataField: "activity_name",
      text: t("table_header.activity_type"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      },
      headerStyle: () => {
        return { width: "20%" }
      }
    },

    {
      dataField: "activityDate",
      text: t("table_header.activity_date"),
      sort: true,
      sortCaret: sortCaret,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          sort: field + "," + order,
          ...initialFilterTable
        })
        history.push({ pathname: location.pathname, search: param })
      },
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiActivityDate
      }
    },
    {
      dataField: "createdDate",
      text: t("table_header.created_date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          sort: field + "," + order,
          ...initialFilterTable
        })
        history.push({ pathname: location.pathname, search: param })
      },
      formatExtraData: {
        externalStyle: uiCreatedDate
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  function uiAction(e) {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
          <a
            onClick={() => {
              linkDetail(e?.area_id, e?.activity_type_enum.toLowerCase(), e?.id, e)
            }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DetailSvgIcon></DetailSvgIcon>
          </a>
        </OverlayTrigger>
      </>
    )
  }

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          <NoteView title={t("label.note_view_activity_report_v2")} />
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageDataActivity?.length == 0 ? (
            <EmptyCard
              subtitle={isFilter && t("messages.response.empty_search_subtitle")}
              image={
                isFilter
                  ? ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA
              }
              body={
                isFilter
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", {
                      name: t("menu_aside.area_activity_report")
                    })
              }
            />
          ) : (
            ""
          )}

          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data.pageDataActivity}
            currentPage={data.pageActivity.currentPage}
            currentElement={data.pageActivity.currentElement}
            totalCount={data.pageActivity.totalCount}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            keyField={"id"}
            selectable={true}
            onSelect={(row, isSelected) => {
              let rows = JSON.parse(JSON.stringify(selectedRows))
              if (isSelected) {
                rows.push(row)
              } else {
                rows = rows.filter((e) => e?.id !== row?.id)
              }

              setSelectedRows(rows)
            }}
            onSelectAll={(isSelect, rows) => {
              let rowses = JSON.parse(JSON.stringify(selectedRows))
              if (isSelect) {
                if (rows?.length > 0) {
                  rows?.map((e) => {
                    rowses.push(e)
                  })
                }
              } else {
                if (rows?.length > 0) {
                  let rowsesNew = rowses
                  rows?.map((row) => {
                    const removeIndex = rowses.findIndex((e) => e?.id === row?.id)
                    rowsesNew.splice(removeIndex, 1)
                  })
                  rowses = rowsesNew
                }
              }
              setSelectedRows(rowses)
            }}
          />
        </CardBody>
      </Card>
      {selectedRows.length > 1 ? (
        <>
          <FloatingSelectArea
            infoText={
              <div className={"d-flex"}>
                <img src={ICON_CONSTANS.IC_WARNING} alt={""} className={"mr-2"} />
                <div className={"text-warning"}>{t("label.warning_select_activity")}</div>
              </div>
            }
            disableButton={selectedRows.length > 20}
            showInfo={selectedRows.length > 20}
            label={t("label.activity_selected_count", { count })}
            labelIcon={ICON_CONSTANS.IC_TASK_WHITE}
            buttonlabel={t("label.send_to_bulk_finance")}
            buttonColor={"primary"}
            handleClick={handleBulkErp}
          />
        </>
      ) : (
        ""
      )}
    </>
  )
}
