import { createSlice } from "@reduxjs/toolkit"
import swal from "sweetalert"

export const initState = {
  actionInitType: null,
  loading: false,
  loadingComponent: false,
  loadingGlobal: false,
  isError: false,
  isFinishUpdateOrDelete: false,
  errorMsg: null,
  errorStatus: null,
  iconSwall: "",
  isFinishSyncErp: false
}

export const GeneralSlice = createSlice({
  name: "general",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      const { loading, actionInitType } = action.payload
      state.actionInitType = actionInitType
      state.loading = loading
      state.isError = false
      state.isFinishUpdateOrDelete = false
      state.errorMsg = null
      state.errorStatus = null
    },
    setLoadingComponent: (state, action) => {
      const { loading, actionInitType } = action.payload
      state.actionInitType = actionInitType
      state.loadingComponent = loading
      state.isError = false
      state.isFinishUpdateOrDelete = false
      state.errorMsg = null
      state.errorStatus = null
    },
    setLoadingGlobal: (state, action) => {
      const { loading, actionInitType } = action.payload
      state.actionInitType = actionInitType
      state.loadingGlobal = loading
      state.isError = false
      state.isFinishUpdateOrDelete = false
      state.errorMsg = null
      state.errorStatus = null
    },
    setError: (state, action) => {
      const { customModal, message } = action.payload
      state.loadingGlobal = false
      state.loading = false
      state.loadingComponent = false
      state.isError = true
      state.isFinishUpdateOrDelete = false
      if (!customModal) {
        swal("Gagal", action?.payload?.message ?? "Terjadi Kesalahan Pada Sistem", {
          icon: "error"
        }).then() // TODO : blank screen if multiple errors occur simultaneously
      }
      if (message) {
        state.errorMsg = message
      }
    },

    finishUpdateApi: (state, action) => {
      const { isFinishUpdateOrDelete, actionInitType } = action.payload
      state.actionInitType = actionInitType
      state.isError = false
      state.isFinishUpdateOrDelete = isFinishUpdateOrDelete
      state.errorMsg = null
      state.errorStatus = null
    },

    finistUpdateErp: (state, action) => {
      state.isFinishSyncErp = action.payload.isFinishSyncErp
    }
  }
})