import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import TableView, { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { EmptyCard, RowText, SwitchToggle } from "../../../../../component/atoms/index.jsx"

import {
  getDetailPackage,
  getDetailTreatmentPackage,
  updateStatusPackage
} from "../../../../../redux/actions/MasterDataAction.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { DetailSvgIcon, EditSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { Skeleton } from "@material-ui/lab"
import { TableSkeleton } from "../../../../../component/index.jsx"
import { useTranslation } from "react-i18next"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { toast } from "react-toastify"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import SVG from "react-inlinesvg"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { MASTER_DATA } from "../../../../../constants/InitTypeConstants.js"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const PackageDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const { t } = useTranslation()
  const privileges = loadUserLogin().privilege

  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, MasterData } = useSelector((state) => state)
  const [detail, setDetail] = useState("")
  const [tableDetail, setTableDetail] = useState(false)
  const [popTitle, setPopTitle] = useState("")
  const [detailTreatment, setDetailTreatment] = useState("")
  const [showModalConfirmationChangeStatus, setShowModalConfirmationChangeStatus] = useState(false)
  const editButton = () => {
    return (
      <Button
        variant="outline-primary"
        onClick={() => {
          history.push("/master/package/list/edit/" + id)
        }}>
        Edit Data Paket
      </Button>
    )
  }

  useEffect(() => {
    dispatch(getDetailPackage(id))
  }, [])

  useEffect(() => {
    if (MasterData.data) {
      setDetail(MasterData.data)
    }
  }, [MasterData])

  useEffect(() => {
    if (MasterData.treatmentDetail) setDetailTreatment(MasterData.treatmentDetail)
  }, [MasterData.treatmentDetail])

  useEffect(() => {
    if (detail) {
      subHeader.setButton(
        privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_PACKAGE) > -1 ? editButton : undefined
      )
      subHeader.setBreadcrumbs([
        {
          pathname: "/master/package",
          title: "Paket Penyemprotan"
        },
        {
          title: detail?.name
        }
      ])
    }
  }, [detail])

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.code ?? "-"}</p>
      </>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.name ?? "-"}</p>
      </>
    )
  }
  const uiHst = (e) => {
    return (
      <>
        <p className="text-dark mb-1 font-size-lg">{`${e?.hst_start || 0} - ${e?.hst_end}`} </p>
      </>
    )
  }
  const uiAction = (e) => {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
          <a
            onClick={() => {
              setTableDetail(true)
              setPopTitle(e?.name)
              dispatch(getDetailTreatmentPackage(e?.id))
            }}
            className="btn btn-icon btn-hover-primary btn-sm mx-3">
            <DetailSvgIcon></DetailSvgIcon>
          </a>
        </OverlayTrigger>
        {privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_TREATMENT) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <a
              onClick={() => {
                history.push(
                  RoutesConstants.MASTER_DATA.TREATMENT.EDIT(id, detail?.plant_type_id, e.seq, e.id)
                )
              }}
              className="btn btn-icon btn-hover-secondary btn-sm mx-3">
              <EditSvgIcon></EditSvgIcon>
            </a>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const columns = [
    {
      dataField: "seq",
      text: "URUTAN",
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => <div className={"text-dark font-weight-bolder"}>{e?.seq}</div>
      }
    },
    {
      dataField: "code",
      text: "ID TREATMENT",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },

    {
      dataField: "name",
      text: "NAMA TREATMENT",
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "variant_total",
      text: t("label.total_pesticide"),
      sort: false,
      formatter: formatterRowComponent
    },
    {
      dataField: "unit_name",
      text: "HST",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiHst
      }
    },
    {
      dataField: "action",

      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...param
      }
      if (param.sortField) {
        newParam = {
          ...newParam,
          sort: param.sortField + "," + param.sortOrder
        }
      }
      dispatch(getDetailPackage({ pageReq: newParam }))
    },
    [dispatch]
  )

  const uiNameVariant = (e) => <div className={"font-weight-bolder"}>{e.name}</div>
  const uiShapeVarian = (e) => <div className={"font-weight-bolder"}>{e.shape_name}</div>

  const uiDose = (e) => <div>{checkValue(e.total_dose)}</div>

  const columnsDetailTreatment = [
    {
      dataField: "name",
      text: "NAMA VARIANT",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiNameVariant
      }
    },
    {
      dataField: "shape_name",
      text: "BENTUK PESTISIDA",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiShapeVarian
      }
    },
    {
      dataField: "total_dose",
      text: "Jumlah Dosis",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDose
      }
    },
    {
      dataField: "unit",
      text: "UNIT",
      sort: false,
      formatter: formatterRowComponent
    }
  ]

  function onClickUpdateStatusPackage() {
    if (privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_PACKAGE) > -1) {
      setShowModalConfirmationChangeStatus(true)
    }
  }

  function showToastSuccess() {
    toast(detail?.active ? "Paket berhasil dinonaktifkan" : "Paket berhasil diaktifkan", {
      position: "top-center",
      autoClose: 5000,
      style: {
        background: "#353535"
      },
      icon: <SVG src={ICON_CONSTANS.IC_BULLET_TICK} />,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark"
    })
  }

  function componentModalConfirmation() {
    return (
      <RowModule>
        <p className={"swal-text p-4"}>
          {t(
            detail?.active
              ? "messages.confirmation.change_status_package"
              : "messages.confirmation.change_status_package_active"
          )}
        </p>
      </RowModule>
    )
  }

  useEffect(() => {
    if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === MASTER_DATA.UPDATE_STATUS_PACKAGE
    ) {
      setShowModalConfirmationChangeStatus(false)
      dispatch(getDetailPackage(id))
      showToastSuccess()
    }
  }, [General.isFinishUpdateOrDelete])

  function onOkeChangeStatus() {
    dispatch(updateStatusPackage(id))
  }

  useEffect(() => {
    console.log(detail?.active)
  }, [detail])

  return (
    <>
      <PopupQuestion
        onCancel={() => setShowModalConfirmationChangeStatus(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.change_status_package")}
        onOk={onOkeChangeStatus}
        show={showModalConfirmationChangeStatus}
        bodyCustom={componentModalConfirmation()}
      />
      <Card>
        <CardBody>
          {General?.loading ? (
            <Skeleton height={32} variant={"rect"} width={300} />
          ) : (
            <h1 className={"pl-3 pt-3"} style={{ fontSize: "20px" }}>
              {detail?.name ?? "-"}
            </h1>
          )}
          <div className="col-12 row">
            <div className="col-12 row">
              <div className="col-3 d-flex mt-8 flex-column flex-grow-1 pr-8">
                <span className="mb-2">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={100} />
                  ) : (
                    t("label.package_id")
                  )}
                </span>
                <span style={{ fontSize: "14px" }} className=" font-weight-bold">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={200} height={32} />
                  ) : (
                    detail?.code || "-"
                  )}
                </span>
              </div>
              <div className="col-3 d-flex mt-8 flex-column flex-grow-1 pr-8">
                <span className="mb-2">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={100} />
                  ) : (
                    "Tipe Tanaman"
                  )}
                </span>
                <span style={{ fontSize: "14px" }} className=" font-weight-bold">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={200} height={32} />
                  ) : (
                    detail?.plant_type ?? "-"
                  )}
                </span>
              </div>
              <div className="col-3 d-flex mt-8 flex-column flex-grow-1 pr-8">
                <span className="mb-2">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={100} />
                  ) : (
                    "Jumlah Treatment"
                  )}
                </span>
                <span style={{ fontSize: "14px" }} className=" font-weight-bold">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={200} height={32} />
                  ) : (
                    detail?.total_treatment || "0"
                  )}
                </span>
              </div>
              <div
                style={{ justifyContent: "end", alignItems: "end" }}
                className="col-3 d-flex mt-8 flex-column  pr-8">
                <span className="mb-2">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={100} />
                  ) : (
                    t("label.package_status")
                  )}
                </span>
                <span className=" font-weight-bold">
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={200} height={32} />
                  ) : (
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        width: "fit-content"
                      }}
                      onClick={onClickUpdateStatusPackage}>
                      <SwitchToggle
                        disabled={privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_PACKAGE) <= -1}
                        checked={detail?.active}
                      />
                      <div style={{ fontSize: 12 }}>{detail?.active ? "Active" : "Inactive"}</div>
                    </div>
                  )}
                </span>
              </div>
            </div>

            <div className="col-3 d-flex mt-8 flex-column flex-grow-1 pr-8">
              <span className="mb-2">
                {General.loading ? (
                  <Skeleton animation="wave" variant={"text"} width={100} />
                ) : (
                  t("label.regional")
                )}
              </span>
              <span style={{ fontSize: "14px" }} className=" font-weight-bold">
                {General.loading ? (
                  <Skeleton animation="wave" variant={"text"} width={200} height={32} />
                ) : (
                  detail?.regional_name || "-"
                )}
              </span>
            </div>
            <div className="col-6 d-flex mt-8 flex-column flex-grow-1 pr-8">
              <span className="mb-2">
                {General.loading ? (
                  <Skeleton animation="wave" variant={"text"} width={100} />
                ) : (
                  "Deskripsi"
                )}
              </span>
              <span style={{ fontSize: "14px" }} className=" font-weight-bold">
                {General.loading ? (
                  <Skeleton animation="wave" variant={"text"} width={200} height={32} />
                ) : (
                  detail?.desc ?? "-"
                )}
              </span>
            </div>
          </div>
        </CardBody>
      </Card>

      {General.loading ? (
        <Card>
          <TableSkeleton column={6} row={10} />
        </Card>
      ) : (
        <Card>
          <RowModule>
            <Card className="card-shadowless">
              <div className={"align-items-center p-10 d-flex justify-content-between"}>
                <h4 className="mb-0" style={{ fontSize: "20px" }}>
                  Treatment
                </h4>
                {privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_TREATMENT) > -1 && (
                  <Button
                    variant={"primary"}
                    onClick={() =>
                      history.push(
                        RoutesConstants.MASTER_DATA.TREATMENT.NEW(
                          id,
                          detail?.plant_type_id,
                          detail?.total_treatment + 1
                        )
                      )
                    }>
                    Tambah Treatment
                  </Button>
                )}
              </div>
              <CardBody>
                {detail?.treatment_list?.length > 0 ? (
                  <TableView
                    callbackAfterPageChange={changePage}
                    columnProperties={columns}
                    dataTable={detail?.treatment_list}
                    currentPage={0}
                    disablePagination={true}
                    currentElement={detail?.treatment_list?.length}
                    totalCount={detail?.treatment_list?.length}
                    loadingGetData={General.loading}
                    ignoreSinglePage
                    rowClasses={() => {
                      return "cursor-pointer"
                    }}
                    rowEvents={{
                      onClick: () => {}
                    }}
                  />
                ) : (
                  <EmptyCard
                    image={ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DESKTOP}
                    body={t("messages.response.empty_state", { name: "treatment" })}
                  />
                )}
              </CardBody>
            </Card>
          </RowModule>
        </Card>
      )}

      <Modal
        show={tableDetail}
        centered={true}
        contentClassName="modal-image"
        onHide={() => {
          setTableDetail(false)
        }}
        size="lg">
        <Modal.Header closeButton={true}>
          <h5 className="mb-0">{popTitle}</h5>
        </Modal.Header>
        <Modal.Body>
          <div
            className="m-3 mb-8"
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#D1D1D1",
              borderRadius: "4px",
              padding: "34px"
            }}>
            <div className={"d-flex row"}>
              <div className={"col-3"}>
                <RowText
                  label={t("label.id_treatment")}
                  value={checkValue(detailTreatment?.treatment_code)}
                />
              </div>
              <div className={"col-3"}>
                <RowText
                  label={t("label.total_pesticide")}
                  value={checkValue(detailTreatment?.total_pesticide)}
                />
              </div>
              <div className={"col-3"}>
                <RowText
                  label={t("label.hst")}
                  value={checkValue(detailTreatment?.hst_start + " - " + detailTreatment?.hst_end)}
                />
              </div>
              <div className={"col-3"}>
                <RowText
                  label={t("label.description")}
                  value={checkValue(detailTreatment?.description)}
                />
              </div>
            </div>
          </div>
          {detailTreatment?.variant_list?.length > 0 && (
            <TableView
              callbackAfterPageChange={null}
              columnProperties={columnsDetailTreatment}
              dataTable={detailTreatment?.variant_list}
              currentPage={0}
              disablePagination={true}
              loadingGetData={General.loading}
              rowClasses={() => {
                return "cursor-pointer"
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}