import { Field } from "formik"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import { FieldFeedbackLabel } from "../../../_metronic/_partials/controls"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

const getFieldCSSClasses = (touched, errors, editable) => {
  const classes = [" react-select"]
  if (editable) {
    if (touched && errors) {
      classes.push("is-invalid")
    }

    if (touched && !errors) {
      classes.push("is-valid")
    }
  }
  return classes.join(" ")
}

const getFieldStyleClasses = (touched, errors, editable) => {
  let isError = false
  let isValid = false
  if (editable) {
    isValid = touched && !errors
    isError = touched && errors
  }
  return {
    control: (base, state) => ({
      ...base,
      background: editable ? "#fff" : "#F8F8F8",
      // match with the menu
      borderRadius: state.isFocused ? "0.42rem" : "0.42rem",
      // Overwrittes the different states of border
      borderColor: isError ? "#F64E60" : isValid ? "#3699FF" : "#E4E6EF",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null
    })
  }
}

export const CustomSelect = ({
  placeholder,
  field,
  label,
  form,
  options,
  editable = true,
  customFeedbackLabel,
  onChange,
  isMulti = false,
  menuIsOpen,
  value,
  withoutFeedback,
  isClearable,
  chooseOptionWithLabel = false,
  required
}) => {
  const { General } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(General.loading)
  }, [General])

  const disabledInput = () => {
    if (editable) {
      return !loading
    }
    return editable
  }
  useEffect(() => {
    if (value === "") {
      form.setFieldValue(field.name, value)
    }
  }, [value])
  const onChangeCustom = (option) => {
    form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option?.value)
    if (onChange) {
      onChange(isMulti ? option.map((item) => item.value) : option === null ? null : option?.value)
    }
  }
  const onChangeCustomWithLabel = (option) => {
    form.setFieldValue(field.name, isMulti ? option.map((item) => item) : option)
    if (onChange) {
      onChange(isMulti ? option.map((item) => item) : option)
    }
  }
  const getValue = () => {
    if (value === "" || value === undefined) {
      return ""
    }
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value)
    } else {
      return isMulti ? [] : ""
    }
  }
  return (
    <>
      {General.loading ? (
        <Skeleton animation="wave" variant={"text"} width={200} className={"mb-4"} />
      ) : (
        label && (
          <label>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </label>
        )
      )}

      {General.loading ? (
        <Skeleton animation="wave" variant={"rect"} height={40} />
      ) : (
        <Select
          styles={getFieldStyleClasses(form.touched[field.name], form.errors[field.name], editable)}
          className={getFieldCSSClasses(
            form.touched[field.name],
            form.errors[field.name],
            editable
          )}
          name={field.name}
          value={getValue()}
          onChange={chooseOptionWithLabel ? onChangeCustomWithLabel : onChangeCustom}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          isDisabled={!disabledInput()}
          isClearable={isClearable}
          menuIsOpen={menuIsOpen}
          onBlur={() => form.setTouched({ ...form.touched, [field.name]: true })}
        />
      )}
      {!withoutFeedback && editable && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}

export default CustomSelect

export const SelectInput = ({
  name,
  placeholder,
  options,
  label,
  editable,
  value,
  onChange,
  isMulti,
  menuIsOpen,
  withoutFeedback,
  chooseOptionWithLabel = false,
  isClearable = true,
  required
}) => {
  return (
    <Field
      isClearable={isClearable}
      name={name}
      label={label}
      options={options}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      editable={editable}
      onChange={onChange}
      value={value}
      withoutFeedback={withoutFeedback}
      chooseOptionWithLabel={chooseOptionWithLabel}
      required={required}
    />
  )
}