import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import {
  CheckboxLabelButton,
  EmptyCard,
  FilterCard,
  NoteView,
  SelectInput,
  TableSkeleton
} from "../../../../component/index.jsx"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel/index.jsx"
import SVG from "react-inlinesvg"
import { linkWithCtrl } from "../../../helpers/Utils.js"
import { useDispatch, useSelector } from "react-redux"
import { convertM2ToHectare } from "../../../helpers/TextHelper.js"
import { dateSelect, timeStampToDate } from "../../../helpers/DateHelper.js"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { DetailSvgIcon } from "../../../../component/atoms/Icons/SVG/index.jsx"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { getListSeedingBaReport } from "../../../../redux/actions/AreaAction.jsx"
import { getListFarmer } from "../../../../redux/actions/FarmerAction.jsx"
import {
  getActivityStatusEnumFilter,
  getActivityTypeList,
  getPlantType
} from "../../../../redux/actions/MasterDataAction.jsx"
import { clearDataDetail, getAllMitra } from "../../../../redux/actions/MitraAction.jsx"
import { format } from "date-fns"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { CalenderSvgIcon } from "../../../../component/atoms/Icons/SVG/calender.jsx"
import { DateRange } from "react-date-range"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { Formik } from "formik"

export function SeedingBaPage() {
  const subheader = useSubheader()
  const { t } = useTranslation()
  const formRef = useRef()
  const history = useHistory()
  const location = useLocation()
  const urlParams = useUrlParam()
  const dispatch = useDispatch()
  const { General, Area, Mitralist, MasterData, Farmer } = useSelector((state) => state)

  const [isFilterEmptyState, setIsFilterEmptyState] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [showFilter, setShowFilter] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [typeValue, setTypeValue] = useState([])
  const [typeValueStatus, setTypeValueStatus] = useState([])
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dateRange, setDateRange] = useState("")
  const [openDate, setOpenDate] = useState(false)
  const [mitra, setMitra] = useState("")
  const [farmer, setFarmer] = useState("")
  const [isType, setIsType] = useState([])
  const [typeId, setTypeId] = useState("")
  const [stateMitra, setStateMitra] = useState()
  const [stateFarmer, setStateFarmer] = useState([])
  const [openPopupDownloadHistory, setOpenPopupDownloadHistory] = useState(false)
  const isReadOnly = false
  const [search, setSearch] = useState({
    keyword: ""
  })
  const [initialValue, setInitialValue] = useState({
    mitra_id: "",
    activity_type: "",
    status: "",
    start_date: "",
    farmer_id: "",
    end_date: "",
    plant_type: ""
  })
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection"
    }
  ])

  function fetchData(param) {
    dispatch(getListSeedingBaReport(param))
  }

  function buttonDownload() {
    return (
      <div>
        <button
          onClick={() => setOpenPopupDownloadHistory(true)}
          className={"btn btn-outline-primary d-flex"}>
          <SVG src={ICON_CONSTANS.IC_DOWNLOAD_CLOUD} />
          <div className={"ml-4"}>{t("button.download_history")}</div>
        </button>
      </div>
    )
  }

  useEffect(() => {
    subheader.setTitle(t("sub_header.activity_report_kur"))
    subheader.setButton(buttonDownload())
    dispatch(getListFarmer())
    dispatch(getPlantType())
    dispatch(getActivityStatusEnumFilter())
    dispatch(getActivityTypeList())
    dispatch(getAllMitra())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (MasterData.activityTypeList) {
      setIsType(
        MasterData.activityTypeList.map((item) => {
          return {
            enum: item.id,
            label: item.name,
            checked: typeValue.findIndex((e) => e === item.id) >= 0
          }
        })
      )
    }
  }, [MasterData.activityTypeList, typeValue])

  useEffect(() => {
    if (Farmer.list) {
      setStateFarmer(
        Farmer.list.map((value) => ({
          label: `${value.name}, ${value.code}`,
          value: value.id
        }))
      )
    }
  }, [Farmer.list])
  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(
        Mitralist.data.map((value) => ({
          label: `${value.name}, ${value.code}`,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (
      formRef.current.values?.mitra_id ||
      formRef.current.values?.farmer_id ||
      typeId.length > 0 ||
      typeValue.length > 0 ||
      typeValueStatus.length > 0 ||
      dateRange
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, mitra, typeValue, typeValueStatus, dateRange, typeId, farmer])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.mitra_id ||
      queryStringObj?.farmer_id ||
      queryStringObj?.activity_type ||
      queryStringObj?.status ||
      queryStringObj?.plant_type_id ||
      queryStringObj?.start_date ||
      queryStringObj?.end_date
    ) {
      const data = {
        ...initialFilterTable,
        mitra_id: queryStringObj?.mitra_id,
        farmer_id: queryStringObj?.farmer_id,
        activity_type: queryStringObj?.activity_type,
        status: queryStringObj?.status,
        start_date: queryStringObj?.start_date,
        end_date: queryStringObj?.end_date,
        plant_type_id: queryStringObj?.plant_type_id
      }
      if (data) {
        setFilterOn(true)
        setInitialValue(data)
        setTypeId(data?.plant_type_id ?? "")
        setMitra(data?.mitra_id ?? "")
        setFarmer(data?.farmer_id ?? "")
        if (data?.activity_type) {
          setTypeValue(data?.activity_type.split(","))
        }
        if (data?.status) {
          setTypeValueStatus(data?.status.split(","))
        }
        if (data?.end_date && data.start_date) {
          let selectedDate =
            dateSelect(format(new Date(data.start_date), "yyyy-MM-dd")) +
            " s/d " +
            dateSelect(format(new Date(data.end_date), "yyyy-MM-dd"))
          setDateRange(selectedDate)
          setState([
            {
              startDate: new Date(data?.start_date),
              endDate: new Date(data?.end_date),
              key: "selection"
            }
          ])
        } else {
          setDateRange("")
        }
      }
    }
  }, [queryStringObj])

  function searchFunc(values) {
    setSearch({ keyword: values.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function onSubmitForm(e) {
    const valueArray = 0
    const { values, submited } = e
    setShowFilter(false)
    setFilterOn(true)
    if (submited) {
      let paramData = {
        ...initialFilterTable,
        mitra_id: values?.mitra_id,
        activity_type: typeValue,
        status: typeValueStatus,
        plant_type_id: typeId,
        farmer_id: values?.farmer_id,
        start_date: state[valueArray].startDate
          ? format(state[valueArray].startDate, "yyyy-MM-dd")
          : "",
        end_date: state[valueArray].endDate ? format(state[valueArray].endDate, "yyyy-MM-dd") : "",
        keyword: search.keyword
      }
      if (paramData) {
        setInitialValue(paramData)
        const urlParam = getUrlParsingPageFilter(paramData)
        history.push({ pathname: location.pathname, search: urlParam })
      }
    }
  }

  function onChangeMitra(e) {
    setMitra(e)
  }

  function onChangeFarmer(e) {
    setFarmer(e)
  }

  function filterOnReset() {
    setDateRange("")
    setShowFilter(false)
    setFilterOn(false)
    setFarmer("")
    setMitra("")
    setTypeId("")
    setTypeValue([])
    setTypeValueStatus([])
    setState({
      startDate: "",
      endDate: "",
      key: "selection"
    })
    setInitialValue({
      ...initialFilterTable,
      mitra_id: "",
      activity_type: "",
      status: "",
      start_date: "",
      end_date: "",
      plant_type: "",
      farmer_id: ""
    })
    setShowFilter(false)
    setFilterOn(false)

    setInitialValue({
      activity_type: "",
      status: "",
      mitra_id: "",
      farmer_id: "",
      plant_type_id: "",
      start_date: "",
      end_date: ""
    })
    resetDate()
    setTypeValue([])
    let param = {
      ...initialFilterTable,
      activity_type: "",
      status: "",
      mitra_id: "",
      farmer_id: "",
      plant_type_id: "",
      start_date: "",
      end_date: "",
      keyword: search.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function handleReset() {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ keyword: "" })
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function handleOpenCloseFilter(value) {
    setShowFilter(value)
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search, initialValue]
  )

  useEffect(() => {
    if (filterOn) {
      setIsFilterEmptyState(true)
    } else if (isActiveSearch) {
      setIsFilterEmptyState(true)
    } else {
      setIsFilterEmptyState(false)
    }
  }, [isActiveSearch, filterOn, location])

  function handleType(data) {
    const { name, checked } = data.target
    if (checked) {
      setTypeValue([...typeValue, name])
    } else {
      let filteredStatus = typeValue.filter((item) => item !== name)
      setTypeValue(filteredStatus)
    }
  }

  function submitLog() {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, "yyyy-MM-dd")) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, "yyyy-MM-dd"))
    setDateRange(selectedDate)
    setOpenDate(false)
  }

  function resetDate() {
    setOpenDate(false)
    setDateRange("")
    setState([
      {
        startDate: "",
        endDate: "",
        key: "selection"
      }
    ])
  }

  function selectDate() {
    return (
      <div className={"d-flex justify-content-end"}>
        <div
          onClick={() => setOpenDate(!openDate)}
          className={
            "select-date d-flex justify-content-between align-items-center cursor-pointer"
          }>
          <label className={"mb-0 txt-12 cursor-pointer"}>
            {dateRange ? dateRange : "Pilih Range Tanggal"}
          </label>
          <CalenderSvgIcon />
        </div>
      </div>
    )
  }

  function dateRangePicker() {
    return (
      <div style={{ transform: `translateY(23px)` }}>
        <div className={"date-picker-custom "} style={{ fontSize: 11 }}>
          <DateRange
            className={"font-10"}
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
          <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
            <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
              Reset Tanggal
            </button>
            <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
              Terapkan
            </button>
          </div>
        </div>
      </div>
    )
  }

  function filterComponent() {
    return (
      <>
        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.mitra")} className="px-0 min-h-2" />
          <CardBody className="px-0 py-5">
            <RowModule>
              <SelectInput
                name="mitra_id"
                onChange={onChangeMitra}
                editable={!isReadOnly}
                label="Nama Mitra"
                placeholder="Pilih Salah Satu"
                options={stateMitra}
                withoutFeedback={true}
                clearNow={true}
                value={mitra}
              />
            </RowModule>
          </CardBody>
        </Card>
        <Card className="card-custom card-shadowless ">
          <CardHeader title={t("label.farmer")} className="px-0 min-h-2" />
          <CardBody className="px-0 py-5">
            <RowModule>
              <SelectInput
                name="farmer_id"
                onChange={onChangeFarmer}
                editable={!isReadOnly}
                label="Nama Petani"
                placeholder="Pilih Salah Satu"
                options={stateFarmer}
                withoutFeedback={true}
                clearNow={true}
                value={farmer}
              />
            </RowModule>
          </CardBody>
        </Card>

        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.activity_type")} className="px-0" />
          <CardBody className="px-0">
            <CheckboxLabelButton
              formInitialValues={typeValue}
              isStatus={isType}
              name={"activity_type"}
              innerRef={formRef}
              handleChange={handleType}
            />
          </CardBody>
        </Card>

        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.created_date")} className="px-0 min-h-2" />
          <CardBody className="px-0 py-5">
            {selectDate()}
            {openDate && dateRangePicker()}
          </CardBody>
        </Card>
      </>
    )
  }

  useEffect(() => {
    subheader.setTitle(t("sub_header.seeding_minutes_report"))
  }, [])

  const columns = [
    {
      dataField: "area_code",
      text: "Nama Lahan",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div>
            <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.area_name || "-"}</p>
            <p>{`${e?.block_code || "-"}, ${
              e?.planting_area ? convertM2ToHectare(e?.planting_area) : "-"
            }h`}</p>
          </div>
        )
      }
    },
    {
      dataField: "land_area",
      text: t("label.block_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div>
            <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.block_code || "-"}</p>
          </div>
        )
      }
    },
    {
      dataField: "mitra",
      text: t("label.mitra"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div>
            <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name || "-"}</p>
            <p>{e?.mitra_phone_number || "-"}</p>
          </div>
        )
      }
    },
    {
      dataField: "farmer",
      text: t("label.farmer"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div>
            <p className="text-dark font-weight-bolder mb-1 font-size-lg">
              {e?.farmer_name || "-"}
            </p>
            <p>{e?.farmer_code || "-"}</p>
          </div>
        )
      }
    },
    {
      dataField: "activityDate",
      text: t("label.activity_date"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className={"text-dark   font-size-lg"}>
            <div>{e.created_date ? timeStampToDate(e.activity_date, "dd-MM-yyyy") : "-"}</div>
            <div>{e.created_date ? timeStampToDate(e.activity_date, "HH:mm:ss") : "-"}</div>
          </div>
        )
      }
    },
    {
      dataField: "createdDate",
      text: t("label.created_date"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className={"text-dark   font-size-lg"}>
            <div>{e.created_date ? timeStampToDate(e.created_date, "dd-MM-yyyy") : "-"}</div>
            <div>{e.created_date ? timeStampToDate(e.created_date, "HH:mm:ss") : "-"}</div>
          </div>
        )
      }
    },
    {
      dataField: "action",
      text: t("label.action"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                history.push(RoutesConstants.AREA.DETAIL_AREA("active", e.area_id))
              }}>
              <DetailSvgIcon></DetailSvgIcon>
            </div>
          </OverlayTrigger>
        )
      }
    }
  ]

  function bodyModalPopupDownloadHistory() {
    return (
      <div>
        <NoteView
          variant=""
          icon={ICON_CONSTANS.IC_INFO}
          title={t("label.download_history_note_view")}
        />
        <div>
          <Formik enableReinitialize onSubmit={(e) => console.log(e)} initialValues={{}}>
            <Form>
              <div>
                <SelectInput
                  name="mitra_id"
                  onChange={onChangeMitra}
                  editable={!isReadOnly}
                  label={t("label.activity_type")}
                  placeholder="Pilih Salah Satu"
                  options={stateMitra}
                  withoutFeedback={true}
                  clearNow={true}
                  value={mitra}
                />
                <div style={{ width: "100%", zIndex: 999, marginTop: 12 }}>
                  <p>{t("label.created_date")}</p>
                  <div className={"d-flex "} style={{ width: "100%" }}>
                    <div
                      style={{ width: "100%" }}
                      onClick={() => setOpenDate(!openDate)}
                      className={
                        "select-date d-flex justify-content-between align-items-center cursor-pointer"
                      }>
                      <label className={"mb-0 txt-12 cursor-pointer"}>
                        {dateRange ? dateRange : "Pilih Range Tanggal"}
                      </label>
                      <CalenderSvgIcon />
                    </div>
                  </div>
                  {openDate && (
                    <div style={{ zIndex: 999 }}>
                      <div className={"date-picker-custom "} style={{ fontSize: 11 }}>
                        <DateRange
                          className={"font-10"}
                          onChange={(item) => setState([item.selection])}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={1}
                          ranges={state}
                          direction="horizontal"
                          preventSnapRefocus={true}
                          calendarFocus="backwards"
                        />
                        <div
                          className={
                            "action-wrapper d-flex justify-content-end py-4 px-4 border-top"
                          }>
                          <button
                            onClick={resetDate}
                            type={"button"}
                            className={"btn btn-outline-primary mx-4"}>
                            Reset Tanggal
                          </button>
                          <button
                            onClick={submitLog}
                            type={"button"}
                            className={"btn btn-primary mx-4"}>
                            Terapkan
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* Popup for download history */}
      <PopupQuestion
        show={openPopupDownloadHistory}
        title={t("label.download_history")}
        body={bodyModalPopupDownloadHistory()}
        textOk={t("button.continue")}
        variantOkButton={"primary"}
        onCancel={() => {
          setOpenPopupDownloadHistory(false)
        }}
        // onOk={() => {
        //   setOpenConfirmation(false)
        //   setSelectedRows([])
        //   handleBulkConfirmationPlantingSeason()
        // }}
      />

      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={"Masukkan nama lahan/blok"}
        onSubmitForm={searchFunc}
        initialValues={search}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent()}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : Area?.pageData?.length == 0 ? (
            <EmptyCard
              subtitle={isFilterEmptyState ? t("messages.response.empty_search_subtitle") : ""}
              image={
                isFilterEmptyState
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA
              }
              body={
                isFilterEmptyState
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", {
                      name: t("sub_header.seeding_minutes_report")
                    })
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={Area?.pageData}
            currentPage={Area.page.currentPage}
            currentElement={Area.page.currentElement}
            totalCount={Area.page.totalCount}
            loadingGetData={General.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                const url = "/lahan/list/lead/" + row.area_lead_id
                if (e.ctrlKey) {
                  linkWithCtrl(url)
                } else {
                  history.push(url)
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
