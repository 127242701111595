import React from "react"
import { TextInputRadio } from "../TextInput"

export const RadioButtonGroup = ({
  name,
  label,
  editable,
  value,
  type = "radio",
  withoutFeedback,
  checked,
  chooseAtBack,
  onClick = (e) => e,
  ...props
}) => {
  const styleChoose = {
    background: "#EFF8ED",
    border: "1px solid #6CC049"
  }

  const style = {
    background: "#fff",
    border: "1px solid #D1D1D1"
  }

  return (
    <>
      <div className="pt-1 pb-1 pr-4 pl-4 rounded mt-2" style={checked ? styleChoose : style}>
        <TextInputRadio
          label={label}
          name={name}
          value={value}
          editable={editable}
          type={type}
          withoutFeedback={withoutFeedback}
          chooseAtBack={chooseAtBack}
          checked={checked}
          onClick={onClick}
          {...props}
        />
      </div>
    </>
  )
}