export const mockDataReportSeedingList = Array.from({ length: 19 }).map(() => {
  return {
    activity_id: "c4c05347-f066-4142-8d60-573cec02bef6",
    activity_name: "c4c05347-f066-4142-8d60-573cec02bef6",
    activity_date: "2022-06-22T00:00:00.000+07:00",
    area_name: "DKH-1-373-0004",
    planting_area: 24754.0,
    sub_district_name: "Padi",
    block_code: "INPARI001",
    farmer_name: "Van",
    farmer_code: "PTN-1-550",
    mitra_name: "Mitra 007",
    mitra_phone_number: "111-21024-121",
    created_date: "2022-06-21T14:42:22.000+07:00"
  }
})

export const mockDataDetailLandClearing = {
  area_information: {
    area_name: "PNG-1-115-0009",
    block_code: "", //new
    farmer_name: "new farmer 2",
    farmer_code: "PTN-1-732",
    mitra_name: "test salsa",
    mitra_code: "112-54268-213",
    full_address: "Jawa Tengah, Kabupaten Purworejo, Purworejo, Pangenrejo",
    area_land_manual: 2000, //meter
    area_land_polygon: 2093.0074899771894, //meter
    plant_type_name: "Jagung",
    variety_name: "P27",
    certification_number: ""
  },
  land_clearing: {
    created_by: "testing",
    hst: "123",
    created_date: new Date().toString(),
    reason_string: "reasong string",
    activity_date: new Date().toString(),
    document_url: "https://google.com",
    worked_area: 1000 //meter
  }
}
