import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearDataDetail, getAreaMap } from "../../../../redux/actions/AreaAction.jsx"
import { getAllMitra } from "../../../../redux/actions/MitraAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Map } from "./component/Map.jsx"
import { FilterCard } from "../../../../component/molecule/index.jsx"
import {
  CheckboxLabelButton,
  LocationMolecule,
  RadioButtonGroup,
  SelectInput,
  TextInput
} from "../../../../component/index.jsx"
import { Popup } from "../../../../component/atoms/Popup/index.jsx"
import { getLegendMap, getPlantType } from "../../../../redux/actions/MasterDataAction.jsx"
import { useTranslation } from "react-i18next"

export const AreaMap = ({ history }) => {
  const subHeader = useSubheader()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { Areamap, Mitralist, MasterData } = useSelector((state) => state)
  const formRef = useRef()
  const formRefFilter = useRef()
  const [filterOn, setFilterOn] = useState(false)
  const [search, setSearch] = useState({
    area_name: ""
  })
  const [stateMitra, setStateMitra] = useState()
  const [showFilter, setShowFilter] = useState(false)
  const [initialValue, setInitialValue] = useState({
    mitra_id: "",
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    min: "",
    max: "",
    planting_status: "",
    plant_type_id: "",
    varietas_id: "",
    hst_min: "",
    hst_max: ""
  })
  const isReadOnly = false
  const [lahan, setLahan] = useState([])
  const [centerMap, setCenterMap] = useState({})
  const [radiusData, setRadiusData] = useState(4)
  const [radius, setRadius] = useState(4000)
  const [zoomMap, setZoomMap] = useState(14)
  const [isFilter, setIsFilter] = useState(false)
  const [strokeOpacity, setStrokeOpacity] = useState(0.3)
  const [currentLoc, setCurrentLoc] = useState({})
  const [showPopupError, setShowPopupError] = useState(false)
  const [reset, setReset] = useState(false)
  const [mitra, setMitra] = useState("")
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [isStatus, setIsStatus] = useState([
    {
      label: "Teregistrasi",
      enum: "ACTIVE",
      checked: false
    },
    {
      label: "Pending",
      enum: "PENDING",
      checked: false
    },
    {
      label: "Rejected",
      enum: "REJECTED",
      checked: false
    },
    {
      label: "Unassign",
      enum: "UNASSIGNED",
      checked: false
    },
    {
      label: "Calon Lahan",
      enum: "LEAD_AREA",
      checked: false
    }
  ])
  const [statusValue, setStatusValue] = useState([])
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [statePlantingStatus] = useState([
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ])
  const [plantingStatusSelect, setPlantingStatusSelect] = useState()
  const [listLegendMap, setListLegendMap] = useState([])
  const [plantType, setPlantType] = useState([])
  const [plantTypeValue, setPlantTypeValue] = useState("")
  const [varietasList, setVarietasList] = useState([])
  const [varietasValue, setVarietasValue] = useState("")
  const [hstMin, setHstMin] = useState("")
  const [hstMax, setHstMax] = useState("")
  useEffect(() => {
    if (
      formRefFilter.current.values.mitra_id ||
      formRefFilter.current.values.province_id ||
      formRefFilter.current.values.min ||
      formRefFilter.current.values.max ||
      formRefFilter.current.values.planting_status ||
      plantingStatusSelect ||
      formRefFilter.current.values.plant_type_id ||
      formRefFilter.current.values.varietas_id ||
      formRefFilter.current.values.hst_max ||
      formRefFilter.current.values.hst_min
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [
    formRefFilter.current,
    mitra,
    min,
    max,
    plantingStatusSelect,
    hstMax,
    hstMin,
    varietasValue,
    plantTypeValue
  ])

  useEffect(() => {
    dispatch(getAllMitra())
    dispatch(getLegendMap())
    dispatch(getPlantType())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (MasterData?.plantType) {
      setPlantType(
        MasterData?.plantType.map((value) => ({
          label: value?.name,
          value: value.id
        }))
      )
    }
  }, [MasterData?.plantType])

  useEffect(() => {
    if (MasterData.legendMap) {
      setListLegendMap(MasterData.legendMap)
    }
  }, [MasterData.legendMap])

  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (Areamap.pageData.length > 0) {
      setLahan(
        Areamap.pageData.map((item) => {
          return {
            ...item,
            info_marker: false
          }
        })
      )
    } else {
      setLahan([])
    }
  }, [Areamap])

  useEffect(() => {
    if (plantTypeValue) {
      const varietas = MasterData?.plantType.find((item) => item.id === plantTypeValue)
      setVarietasList(
        varietas?.varieties_type.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    } else {
      setVarietasValue("")
      setVarietasList([])
    }
  }, [plantTypeValue])

  useEffect(() => {
    subHeader.setButton(null)
    subHeader.setTitle("Lokasi Lahan")
  }, [])

  const changeZoom = (data, current) => {
    setCenterMap(current)
    if (!isFilter) {
      switch (data) {
        case 16:
          return setRadiusData(2), setRadius(2000)
        case 15:
          return setRadiusData(3), setRadius(3000)
        case 14:
          return setRadiusData(4), setRadius(4000)
        case 13:
          return setRadiusData(8), setRadius(8000)
        case 12:
          return setRadiusData(12), setRadius(12000)
        case 11:
          return setRadiusData(24), setRadius(24000)
        case 10:
          return setRadiusData(48), setRadius(48000)
        case 9:
          return setRadiusData(96), setRadius(96000)
        case 8:
          return setRadiusData(150), setRadius(150000)
        case 7:
          return setRadiusData(700), setRadius(700000)
        case 6:
          return setRadiusData(300), setRadius(300000)
        case 5:
          return setRadiusData(600), setRadius(600000)
      }
    }
  }

  useEffect(() => {
    if (!isFilter) {
      if (centerMap.lat) {
        let param = {
          lat: centerMap.lat,
          lon: centerMap.lng,
          radius: radiusData,
          area_status: statusValue
        }
        dispatch(getAreaMap(param))
      }
    }
  }, [centerMap, radius, radiusData])

  const handleInfo = (id) => {
    const placeIndex = lahan.findIndex((item) => {
      if (item.status === "LEAD_AREA") return item.area_lead_id === id
      else return item.area_id === id
    })
    const update = {
      ...lahan[placeIndex]
    }
    update.info_marker = !update.info_marker

    const newPlace = [...lahan]
    newPlace[placeIndex] = update
    setLahan(newPlace)
  }

  const onLoadMap = (data) => {
    let param = {
      lat: data.lat,
      lon: data.lng,
      radius: radiusData
    }
    setCurrentLoc(param)
  }

  useEffect(() => {
    if (isFilter) {
      if (Areamap.pageData.length === 0) {
        setShowPopupError(true)
      }
    }
  }, [Areamap.pageData])

  const onSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    setMin(values.min)
    setMax(values.max)
    setZoomMap(10)
    setStrokeOpacity(0)
    setIsFilter(true)
    if (submited === true) {
      let param = {
        mitra_id: values.mitra_id,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        min: values.min,
        max: values.max,
        area_name: search.area_name,
        area_status: statusValue,
        planting_status: values.planting_status,
        plant_type_id: plantTypeValue,
        varietas_id: varietasValue,
        hst_min: values.hst_min,
        hst_max: values.hst_max
      }
      if (param) {
        setInitialValue(param)
        dispatch(getAreaMap(param))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const onReset = () => {
    setReset(true)
    setMitra("")
    setMin("")
    setMax("")
    setIsFilter(false)
    setStrokeOpacity(0.3)
    setInitialValue({
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min: "",
      max: "",
      planting_status: "",
      plant_type_id: "",
      varietas_id: "",
      hst_max: "",
      hst_min: ""
    })
    setPlantTypeValue([])
    setPlantingStatusSelect("")
    setStatusValue([])
    setVarietasValue("")
    setPlantTypeValue("")
    setHstMin("")
    setHstMax("")
    let newStatus = isStatus.map((el) => ({
      label: el.label,
      enum: el.enum,
      checked: false
    }))
    setIsStatus(newStatus)
    let param = {
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min: "",
      max: "",
      area_name: search.area_name,
      area_status: "",
      planting_status: "",
      plant_type_id: "",
      varietas_id: ""
    }

    if (search.area_name.length > 0) {
      dispatch(getAreaMap(param))
    } else {
      dispatch(getAreaMap(currentLoc))
      setZoomMap(14)
      setRadius(4000)
    }
    setShowFilter(false)
    setFilterOn(false)
  }

  const handleCenter = (data) => {
    if (!isFilter) {
      let param = {
        lat: data.lat,
        lon: data.lng,
        radius: radiusData,
        area_status: statusValue
      }
      setCenterMap(data)
      dispatch(getAreaMap(param))
    }
  }

  const seacrhFunc = (values) => {
    setIsFilter(true)
    setZoomMap(10)
    setStrokeOpacity(0)
    setSearch({
      area_name: values.area_name
    })
    let param = {
      ...initialValue,
      area_name: values.area_name
    }
    dispatch(getAreaMap(param))
  }

  const handleReset = () => {
    setIsFilter(false)
    setStrokeOpacity(0.3)
    setSearch({
      area_name: ""
    })
    let param = {
      ...initialValue,
      area_name: ""
    }
    if (filterOn) {
      dispatch(getAreaMap(param))
    } else {
      dispatch(getAreaMap(currentLoc))
      setZoomMap(14)
      setRadius(4000)
    }
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  function onChangeMitra(e) {
    setMitra(e)
  }
  function onChangePlantType(e) {
    setPlantTypeValue(e)
    setVarietasValue("")
  }
  function onChangeVarietas(e) {
    setVarietasValue(e)
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.mitra")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              onChange={onChangeMitra}
              editable={!isReadOnly}
              label={t("label.mitra_name")}
              placeholder={t("placeholder.select_one")}
              options={stateMitra}
              withoutFeedback={true}
              clearNow={true}
              value={mitra}
            />
          </RowModule>
        </CardBody>
      </Card>

      <LocationMolecule
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle={t("label.location")}
        removeHorizontalPadding={true}
      />
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.land_area_m2")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.min")}
              name="min"
              placeholder={t("placeholder.min_value")}
              withoutFeedback={true}
              value={min}
              onChangeValue={setMin}
              unit={"m2"}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.max")}
              name="max"
              placeholder={t("placeholder.max_value")}
              withoutFeedback={true}
              value={max}
              onChangeValue={setMax}
              unit={"m2"}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.planting_status")} className="px-0" />
        <CardBody className="px-0">
          {statePlantingStatus.map((item) => (
            <RadioButtonGroup
              editable={false}
              key={item.value}
              checked={plantingStatusSelect === item.value}
              name="planting_status"
              label={item.label}
              value={item.value}
              onClick={(e) => setPlantingStatusSelect(e.target.value)}
            />
          ))}
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.plant")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="plant_type_id"
              onChange={onChangePlantType}
              editable={!isReadOnly}
              label={t("label.plant_type")}
              placeholder={t("placeholder.select_one")}
              options={plantType}
              withoutFeedback={true}
              clearNow={true}
              value={plantTypeValue}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="varietas"
              onChange={onChangeVarietas}
              editable={plantTypeValue ? true : false}
              disabled={plantTypeValue ? false : true}
              label={t("label.varietas")}
              placeholder={t("placeholder.select_one")}
              options={varietasList}
              withoutFeedback={true}
              clearNow={true}
              value={varietasValue}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.plant_age")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.min")}
              name="hst_min"
              placeholder={t("placeholder.min_value")}
              withoutFeedback={true}
              value={hstMin}
              onChangeValue={setHstMin}
              unit={"HST"}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.max")}
              name="hst_max"
              placeholder={t("placeholder.max_value")}
              withoutFeedback={true}
              value={hstMax}
              onChangeValue={setHstMax}
              unit={"HST"}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  useEffect(() => {
    let param = {
      ...initialValue,
      ...currentLoc,
      radius: radiusData,
      area_name: search.area_name,
      area_status: statusValue
    }
    if (param?.lat && param?.lon && param?.radius) {
      dispatch(getAreaMap(param))
    }
  }, [statusValue, currentLoc])

  const handleStatus = (data) => {
    const { name, checked } = data.target
    let filteredStatus = isStatus.filter((item) => item.enum === name)
    let newListStatus = {
      label: filteredStatus[0].label,
      enum: filteredStatus[0].enum,
      checked: checked
    }
    let newStatus = isStatus.map((el) =>
      el.enum === newListStatus.enum ? { ...newListStatus } : el
    )
    setIsStatus(newStatus)
    if (checked) {
      setStatusValue([...statusValue, name])
    } else {
      let filteredStatus = statusValue.filter((item) => item !== name)
      setStatusValue(filteredStatus)
    }
  }

  return (
    <>
      <Popup
        type={"empty"}
        body={<span>Data kosong</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      <FilterCard
        // seacrh
        placeholder={"Masukkan nama lahan"}
        onSubmitForm={seacrhFunc}
        initialValues={search}
        name={"area_name"}
        innerRef={formRefFilter}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRefFilter}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Card>
        <RowModule>
          <Card className="card-custom card-shadowless p-12 mb-0">
            <div className={"mb-10"}>
              <CheckboxLabelButton
                formInitialValues={statusValue}
                isStatus={isStatus}
                name={"status"}
                innerRef={formRef}
                handleChange={handleStatus}
              />
            </div>
            <Map
              onLoadMap={onLoadMap}
              zoom={zoomMap}
              area={lahan}
              handleInfo={handleInfo}
              history={history}
              handleCenter={handleCenter}
              radius={radius}
              changeZoom={changeZoom}
              strokeOpacity={strokeOpacity}
              legendMap={listLegendMap}
              isFilter={isFilter}
            />
          </Card>
        </RowModule>
      </Card>
    </>
  )
}