import { useSubheader } from "../../../../_metronic/layout/index.js"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Table } from "./table/Table.jsx"
import { FilterCard, LocationMolecule, SelectInput } from "../../../../component/index.jsx"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  getUrlParsingPageFilter,
  getUrlParsingSubmission
} from "../../../../config/EndpointCollection.js"
import { dateSelect } from "../../../helpers/DateHelper.js"
import { format } from "date-fns"
import { Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { DateRange } from "react-date-range"
import { CalenderSvgIcon } from "../../../../component/atoms/Icons/SVG/calender.jsx"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { DATE_TYPE_ENUM } from "../../../../constants/StringConstant.js"
import { getMitraFilterPlantingSeason } from "../../../../redux/actions/MitraAction.jsx"
import {
  getListRegionalSelected,
  getPlantType
} from "../../../../redux/actions/MasterDataAction.jsx"
import { getListReadyPlantingSeasonConfirmation } from "../../../../redux/actions/AreaAction.jsx"
import { useLocation } from "react-router"

export const ListReadyPlantingSeasonConfirmation = ({ history }) => {
  const subHeader = useSubheader()
  const formRef = useRef()
  const { t } = useTranslation()
  const { General, Area, Mitra, MasterData } = useSelector((state) => state)
  const urlParams = useUrlParam()
  const location = useLocation()
  const dispatch = useDispatch()
  const userLogin = JSON.parse(localStorage.getItem("userLogin"))

  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [searchValue, setSearchValue] = useState("")
  const [resetLocation, setResetLocation] = useState(false)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    mitra_id: "",
    plant_type_id: "",
    harvest_start_date: "",
    harvest_end_date: ""
  })
  const [openDate, setOpenDate] = useState(false)
  const [dateRange, setDateRange] = useState("")
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection"
    }
  ])
  const [mitraList, setMitraList] = useState([])
  const [mitraFilterValue, setMitraFilterValue] = useState("")
  const [selectedRegional, setSelectedRegional] = useState([])
  const [listRegional, setListRegional] = useState([])
  const [selectedRegionalUser, setSelectedRegionalUser] = useState()
  const [plantType, setPlantType] = useState([])
  const [plantTypeValue, setPlantTypeValue] = useState("")

  useEffect(() => {
    subHeader.setTitle(t(`label.ready_planting_season_confirmation`))
    subHeader.setButton(null)
    dispatch(getMitraFilterPlantingSeason())
    dispatch(getListRegionalSelected())
    dispatch(getPlantType())
    if (userLogin?.regional_id) {
      setSelectedRegionalUser(userLogin?.regional_id)
    }
  }, [])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  useEffect(() => {
    if (Mitra?.data) {
      setMitraList(
        Mitra?.data.map((value) => ({
          label: value?.name,
          value: value?.id
        }))
      )
    }
  }, [Mitra.data])

  useEffect(() => {
    if (MasterData?.listRegionalSelected) {
      setListRegional(
        MasterData.listRegionalSelected.map((item) => {
          return {
            label: item?.name + " (" + item?.code + ")",
            value: item?.id
          }
        })
      )
    }
  }, [MasterData])

  useEffect(() => {
    if (MasterData?.plantType) {
      setPlantType(
        MasterData?.plantType.map((value) => ({
          label: value?.name,
          value: value?.id
        }))
      )
    }
  }, [MasterData?.plantType])

  useEffect(() => {
    if (listRegional && selectedRegional) {
      const data = listRegional.find((e) => e?.value === selectedRegionalUser)
      if (data) {
        setSelectedRegional([data])
      }
    }
  }, [listRegional, selectedRegionalUser])

  useEffect(() => {
    if (formRef.current.values.province_id || mitraFilterValue || plantTypeValue || dateRange)
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, dateRange, mitraFilterValue, plantTypeValue])

  useEffect(() => {
    checkQueryParamOnPageLoad()
    const result = location?.search
    fetchData(result)
  }, [queryStringObj])

  function fetchData(param) {
    dispatch(getListReadyPlantingSeasonConfirmation(param))
  }

  function checkQueryParamOnPageLoad() {
    if (queryStringObj?.keyword) {
      setSearchValue(queryStringObj?.keyword)
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.plant_type_id ||
      queryStringObj?.mitra_id ||
      queryStringObj?.province_id ||
      queryStringObj?.regency_id ||
      queryStringObj?.district_id ||
      queryStringObj?.sub_district_id ||
      queryStringObj?.harvest_start_date ||
      queryStringObj?.harvest_end_date
    ) {
      const data = {
        ...initialFilterTable,
        keyword: queryStringObj?.keyword,
        plant_type_id: queryStringObj?.plant_type_id,
        mitra_id: queryStringObj?.mitra_id,
        province_id: queryStringObj?.province_id,
        regency_id: queryStringObj?.regency_id,
        district_id: queryStringObj?.district_id,
        sub_district_id: queryStringObj?.sub_district_id,
        harvest_start_date: queryStringObj?.harvest_start_date,
        harvest_end_date: queryStringObj?.harvest_end_date
      }
      if (data) {
        setIsActiveFilter(true)
        setInitialValue(data)
        setMitraFilterValue(queryStringObj?.mitra_id)
        setPlantTypeValue(queryStringObj?.plant_type_id)
        if (data?.harvest_start_date && data?.harvest_end_date) {
          let selectedDate =
            dateSelect(format(new Date(data?.harvest_start_date), DATE_TYPE_ENUM.ISO_DATE_EURO)) +
            " s/d " +
            dateSelect(format(new Date(data?.harvest_end_date), DATE_TYPE_ENUM.ISO_DATE_EURO))
          setDateRange(selectedDate)
          setState([
            {
              startDate: new Date(data?.harvest_start_date),
              endDate: new Date(data?.harvest_end_date),
              key: "selection"
            }
          ])
        } else {
          setDateRange("")
        }
      }
    }
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: searchValue
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          keyword: searchValue
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, searchValue, initialValue, location]
  )

  const onSearch = (values) => {
    setSearchValue(values?.keyword)
    setIsActiveSearch(true)
    let param = {
      ...initialValue,
      keyword: values?.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }
  const handleResetSearch = () => {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }
  const handleOpenCloseFilter = (value) => {
    setIsShowFilter(value)
  }
  const onSubmitFilter = (e) => {
    setResetLocation(false)
    const valueArray = 0
    const { values, submited } = e
    if (submited) {
      setIsActiveFilter(true)
      setIsShowFilter(false)
      const paramData = {
        ...initialFilterTable,
        keyword: searchValue,
        mitra_id: values?.mitra_id,
        province_id: values?.province_id,
        regency_id: values?.regency_id,
        district_id: values?.district_id,
        sub_district_id: values?.sub_district_id,
        plant_type_id: values?.plant_type_id,

        harvest_start_date: state[valueArray].startDate
          ? format(state[valueArray].startDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
          : "",
        harvest_end_date: state[valueArray].endDate
          ? format(state[valueArray].endDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
          : ""
      }
      if (paramData) {
        setInitialValue(paramData)
        const urlParam = getUrlParsingPageFilter(paramData)
        history.push({ search: urlParam })
      }
    }
  }
  const onResetFilter = () => {
    setDateRange("")
    setResetLocation(true)
    setIsShowFilter(false)
    setIsActiveFilter(false)
    resetDate()
    const param = {
      ...initialFilterTable,
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      mitra_id: "",
      plant_type_id: "",
      harvest_start_date: "",
      harvest_end_date: ""
    }
    setInitialValue(param)
    setMitraFilterValue("")
    setPlantTypeValue("")
    const urlParam = getUrlParsingSubmission(param)
    history.push({ search: urlParam })
  }
  const submitLog = () => {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray]?.startDate, DATE_TYPE_ENUM.ISO_DATE_EURO)) +
      " s/d " +
      dateSelect(format(state[valueArray]?.endDate, DATE_TYPE_ENUM.ISO_DATE_EURO))
    setDateRange(selectedDate)
    setOpenDate(false)
  }
  const resetDate = () => {
    setOpenDate(false)
    setDateRange("")
    setState([
      {
        startDate: "",
        endDate: "",
        key: "selection"
      }
    ])
  }
  function dateRangePicker() {
    return (
      <div style={{ transform: `translateY(23px)` }}>
        <div className={"date-picker-custom "} style={{ fontSize: 11 }}>
          <DateRange
            className={"font-10"}
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
          <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
            <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
              {t("label.reset")}
            </button>
            <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
              {t("label.apply")}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function selectDate() {
    return (
      <div className={"mt-5"}>
        <div className={"d-flex justify-content-end"}>
          <div
            onClick={() => setOpenDate(!openDate)}
            className={
              "select-date d-flex justify-content-between align-items-center cursor-pointer"
            }>
            <label className={"mb-0 txt-12 cursor-pointer"}>
              {dateRange ? dateRange : "Pilih Range Tanggal"}
            </label>
            <CalenderSvgIcon />
          </div>
        </div>
      </div>
    )
  }

  function checkRegionalSelected() {
    if (userLogin?.regional_id) {
      return userLogin?.regional_id
    } else {
      if (selectedRegional) {
        const data = selectedRegional.map((e) => {
          return e.value
        })
        if (data.length > 0) {
          return data.join(",")
        }
      }
    }
  }

  const checkFilterAndSearch = () => {
    if (isActiveSearch || isActiveFilter) {
      return true
    } else {
      return false
    }
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.mitra")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              label={t("label.mitra_name")}
              editable={true}
              placeholder={t("placeholder.select_one")}
              options={mitraList}
              withoutFeedback={true}
              isClearable={true}
              value={mitraFilterValue}
              onChange={(e) => setMitraFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <LocationMolecule
        resetLocation={resetLocation}
        regionalId={checkRegionalSelected()}
        name="filter"
        withoutFeedback={true}
        isReadOnly={false}
        addressTitle={t("label.location")}
        removeHorizontalPadding={true}
      />
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.plant")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="plant_type_id"
              onChange={(e) => setPlantTypeValue(e)}
              editable={true}
              label={t("label.plant_type")}
              placeholder={t("placeholder.select_one")}
              options={plantType}
              withoutFeedback={true}
              clearNow={true}
              value={plantTypeValue}
              isClearable={true}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.harvest_date")} className={"px-0"} />
        {selectDate()}
        {openDate && dateRangePicker()}
      </Card>
    </>
  )

  return (
    <div className={"d-flex flex-column"}>
      <FilterCard
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.insert_area_name_or_farmer")}
        onSubmitForm={onSearch}
        initialValues={{ keyword: searchValue }}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleResetSearch}
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitFilter}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onResetFilter}
        showFilter={isShowFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={isActiveFilter}
        btnDisable={btnFilterDisable}
      />
      <Table
        data={Area}
        changePage={changePage}
        history={history}
        general={General}
        isActiveSearch={isActiveSearch}
        initialFilterTable={initialFilterTable}
        isFilter={checkFilterAndSearch()}
        fetchData={fetchData}
      />
    </div>
  )
}