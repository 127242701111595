import React, { useEffect } from "react"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toMeter } from "../../../../../helpers/TextHelper.js"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { linkWithCtrl } from "../../../../../helpers/Utils.js"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../../component/index.jsx"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant.js"

export function TabNoBill({ data, changePage, history, general, isFilter }) {
  const subheader = useSubheader()
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.area_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">
          {e?.land_area ? toMeter(e?.land_area) : "-"}
        </span>
      </>
    )
  }
  const uiFarmer = (e) => {
    //using data dummy before endpoint ready
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.farmer_name ? e?.farmer_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.farmer_code ? e?.farmer_code : "-"}
        </span>
      </>
    )
  }
  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e?.mitra_code ?? "-"}</span>
      </>
    )
  }
  const uiPlant = (e) => {
    //using data dummy before endpoint ready
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.plant_type_name ? e?.plant_type_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.variety_name ? e?.variety_name : "-"}
        </span>
      </>
    )
  }
  const uiType = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.activity_name ?? "-"}</p>
      </>
    )
  }

  const uiActivityDate = (e) => {
    const dateConvert = e?.activity_date
      ? timeStampToDate(e?.activity_date, DATE_TYPE_ENUM.ISO_DATE_STRAP)
      : "-"

    return (
      <>
        <p className="text-dark mb-1 font-size-lg">{dateConvert}</p>
      </>
    )
  }
  const uiCreatedDate = (e) => {
    const dateConvert = e?.submitted_date
      ? timeStampToDate(e?.submitted_date, DATE_TYPE_ENUM.ISO_DATE_STRAP)
      : "-"
    const TimeConvert = e?.submitted_date
      ? timeStampToDate(e?.submitted_date, DATE_TYPE_ENUM.ISO_TIME_STRAP)
      : "-"

    return (
      <>
        <p className="text-dark mb-1 font-size-lg">
          {dateConvert} <br /> {TimeConvert}
        </p>
      </>
    )
  }

  const linkDetail = (area_id, type, id, e) => {
    const url = RoutesConstants.AREA.DETAIL_ACTIVITY(area_id, type, id, e)
    if (e?.ctrlKey) {
      linkWithCtrl(url)
    } else {
      history.push(url)
    }
  }

  const columns = [
    {
      dataField: "plantingSeason.area.name",
      text: t("table_header.area_name"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "farmerName",
      text: t("table_header.farmer"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      }
    },
    {
      dataField: "mitraName",
      text: t("table_header.mitra"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "plant_name",
      text: t("table_header.planting_type"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlant
      }
    },
    {
      dataField: "activity_name",
      text: t("table_header.activity_type"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      },
      headerStyle: () => {
        return { width: "20%" }
      }
    },

    {
      dataField: "activityDate",
      text: t("table_header.activity_date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiActivityDate
      }
    },
    {
      dataField: "submittedDate",
      text: t("table_header.created_date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreatedDate
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  function uiAction(e) {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
          <a
            onClick={() => {
              linkDetail(e?.area_id, e?.activity_type_enum.toLowerCase(), e?.id, e)
            }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DetailSvgIcon></DetailSvgIcon>
          </a>
        </OverlayTrigger>
      </>
    )
  }

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageDataActivity?.length == 0 ? (
            <EmptyCard
              subtitle={isFilter && t("messages.response.empty_search_subtitle")}
              image={
                isFilter
                  ? ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA
              }
              body={
                isFilter
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", {
                      name: t("menu_aside.area_activity_report")
                    })
              }
            />
          ) : (
            ""
          )}

          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data.pageDataActivity}
            currentPage={data.pageActivity.currentPage}
            currentElement={data.pageActivity.currentElement}
            totalCount={data.pageActivity.totalCount}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            keyField={"id"}
          />
        </CardBody>
      </Card>
    </>
  )
}
