import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  CheckboxLabelButton,
  FilterCard,
  RadioButtonGroup,
  SelectInput,
  TabsWithIcon
} from "../../../../../component/index.jsx"
import { TabReadySendFinance } from "./tabs/TabReadySendFinance.jsx"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { initialFilterTable } from "../../../../../component/atoms/Tabel/index.jsx"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { format } from "date-fns"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import { DATE_TYPE_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import {
  getListNeedBa,
  getListNoBill,
  getListReadySendFinance,
  getListSendingFinance
} from "../../../../../redux/actions/AreaAction.jsx"
import { TabSendFinance } from "./tabs/TabSendFinance.jsx"
import { TabsNeedBa } from "./tabs/TabsNeedBa.jsx"
import { TabNoBill } from "./tabs/TabNoBill.jsx"
import { getListFarmer } from "../../../../../redux/actions/FarmerAction.jsx"
import {
  getActivityStatusEnumFilter,
  getActivityTypeList,
  getPlantType
} from "../../../../../redux/actions/MasterDataAction.jsx"
import { clearDataDetail, getAllMitra } from "../../../../../redux/actions/MitraAction.jsx"
import { DateRange } from "react-date-range"
import { dateSelect } from "../../../../helpers/DateHelper.js"
import { useUrlParam } from "../../../../hooks/useUrlParams.jsx"

export function ListActivityReportV2({
  match: {
    params: { tab }
  }
}) {
  const subheader = useSubheader()
  const history = useHistory()
  const urlParams = useUrlParam()

  const { t } = useTranslation()
  const { General, Area, Mitralist, MasterData, Farmer } = useSelector((state) => state)

  const formRef = useRef()
  const [isFilterEmptyState, setIsFilterEmptyState] = useState(false)
  const dispatch = useDispatch()
  const [filterOn, setFilterOn] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [tabActive, setTabActive] = useState(
    tab === ROUTING_STATUS.ACTIVITY_REPORT.READY_SEND_FINANCE
      ? 0
      : tab === ROUTING_STATUS.ACTIVITY_REPORT.SENDING_FINANCE
      ? 1
      : tab === ROUTING_STATUS.ACTIVITY_REPORT.NEED_BA
      ? 2
      : 3
  )
  const [typeValue, setTypeValue] = useState([])
  const [typeValueStatus, setTypeValueStatus] = useState([])
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dateRange, setDateRange] = useState("")
  const [openDate, setOpenDate] = useState(false)
  const [mitra, setMitra] = useState("")
  const [farmer, setFarmer] = useState("")
  const [queryStringObj, setQueryStringObj] = useState(undefined)

  const [isType, setIsType] = useState([])
  const [typeId, setTypeId] = useState("")
  const [stateMitra, setStateMitra] = useState()
  const [statePlantType, setStatePlantType] = useState([])
  const [stateFarmer, setStateFarmer] = useState([])
  const isReadOnly = false
  const [search, setSearch] = useState({
    keyword: ""
  })
  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    mitra_id: "",
    activity_type: "",
    status: "",
    start_date: "",
    farmer_id: "",
    end_date: "",
    plant_type: ""
  })
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection"
    }
  ])

  useEffect(() => {
    subheader.setTitle(t("sub_header.activity_report"))
    subheader.setButton(null)

    dispatch(getListFarmer())
    dispatch(getPlantType())
    dispatch(getActivityStatusEnumFilter())
    dispatch(getActivityTypeList())
    dispatch(getAllMitra())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (MasterData.activityTypeList) {
      setIsType(
        MasterData.activityTypeList.map((item) => {
          return {
            enum: item.id,
            label: item.name,
            checked: typeValue.findIndex((e) => e === item.id) >= 0
          }
        })
      )
    }
  }, [MasterData.activityTypeList, typeValue])

  useEffect(() => {
    if (MasterData.plantType) {
      setStatePlantType(
        MasterData.plantType.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.plantType])

  useEffect(() => {
    if (Farmer.list) {
      setStateFarmer(
        Farmer.list.map((value) => ({
          label: `${value.name}, ${value.code}`,
          value: value.id
        }))
      )
    }
  }, [Farmer.list])
  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(
        Mitralist.data.map((value) => ({
          label: `${value.name}, ${value.code}`,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (filterOn) {
      setIsFilterEmptyState(true)
    } else if (isActiveSearch) {
      setIsFilterEmptyState(true)
    } else {
      setIsFilterEmptyState(false)
    }
  }, [isActiveSearch, filterOn, location])

  function fetchData(param) {
    if (tabActive === 1) {
      dispatch(getListSendingFinance(param))
    } else if (tabActive === 0) {
      dispatch(getListReadySendFinance(param))
    } else if (tabActive === 2) {
      dispatch(getListNeedBa(param))
    } else if (tabActive === 3) {
      dispatch(getListNoBill(param))
    }
  }

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  useEffect(() => {
    if (
      formRef.current.values?.mitra_id ||
      formRef.current.values?.farmer_id ||
      typeId.length > 0 ||
      typeValue.length > 0 ||
      typeValueStatus.length > 0 ||
      dateRange
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, mitra, typeValue, typeValueStatus, dateRange, typeId, farmer])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    if (tab) {
      if (tab === ROUTING_STATUS.ACTIVITY_REPORT.SENDING_FINANCE) {
        setTabActive(1)
      } else if (tab === ROUTING_STATUS.ACTIVITY_REPORT.NEED_BA) {
        setTabActive(2)
      } else if (tab === ROUTING_STATUS.ACTIVITY_REPORT.READY_SEND_FINANCE) {
        setTabActive(0)
      } else if (tab === ROUTING_STATUS.ACTIVITY_REPORT.NO_BILL) {
        setTabActive(3)
      }
    }
  }, [tab])

  function handleReset() {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ keyword: "" })
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function onSubmitForm(e) {
    const valueArray = 0
    const { values, submited } = e
    setShowFilter(false)
    setFilterOn(true)
    if (submited) {
      let paramData = {
        ...initialFilterTable,
        mitra_id: values?.mitra_id,
        activity_type: typeValue,
        status: typeValueStatus,
        plant_type_id: typeId,
        farmer_id: values?.farmer_id,
        start_date: state[valueArray].startDate
          ? format(state[valueArray].startDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
          : "",
        end_date: state[valueArray].endDate
          ? format(state[valueArray].endDate, DATE_TYPE_ENUM.ISO_DATE_EURO)
          : "",
        keyword: search.keyword
      }
      if (paramData) {
        setInitialValue(paramData)
        const urlParam = getUrlParsingPageFilter(paramData)
        history.push({ pathname: location.pathname, search: urlParam })
      }
    }
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search, initialValue]
  )

  function getTabContents() {
    const labelList = [
      {
        label: t("label.ready_send_finance")
      },
      {
        label: t("label.sending_finance")
      },
      {
        label: t("label.need_ba")
      },
      {
        label: t("label.no_bill")
      }
    ]

    const componentList = [
      <TabReadySendFinance
        key={0}
        history={history}
        general={General}
        data={Area}
        changePage={changePage}
        isFilter={isFilterEmptyState}
      />,
      <TabSendFinance
        key={1}
        history={history}
        general={General}
        data={Area}
        changePage={changePage}
        isFilter={isFilterEmptyState}
      />,
      <TabsNeedBa
        key={2}
        history={history}
        general={General}
        data={Area}
        changePage={changePage}
        isFilter={isFilterEmptyState}
      />,
      <TabNoBill
        key={3}
        history={history}
        general={General}
        data={Area}
        changePage={changePage}
        isFilter={isFilterEmptyState}
      />
    ]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  function searchFunc(values) {
    setSearch({ keyword: values.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function changeTabs(data) {
    let urlParam = ""

    let param = {
      ...initialValue,
      keyword: search.keyword
    }
    urlParam = getUrlParsingPageFilter(param)
    setTabActive(data)
    if (data === 1) {
      history.push(
        RoutesConstants.AREA.LIST_AREA_ACTIVITY(ROUTING_STATUS.ACTIVITY_REPORT.SENDING_FINANCE) +
          urlParam ?? ""
      )
    } else if (data === 2) {
      history.push(
        RoutesConstants.AREA.LIST_AREA_ACTIVITY(ROUTING_STATUS.ACTIVITY_REPORT.NEED_BA) +
          urlParam ?? ""
      )
    } else if (data === 3) {
      history.push(
        RoutesConstants.AREA.LIST_AREA_ACTIVITY(ROUTING_STATUS.ACTIVITY_REPORT.NO_BILL) +
          urlParam ?? ""
      )
    } else {
      history.push(
        RoutesConstants.AREA.LIST_AREA_ACTIVITY(ROUTING_STATUS.ACTIVITY_REPORT.READY_SEND_FINANCE) +
          urlParam ?? ""
      )
    }
  }

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.mitra_id ||
      queryStringObj?.farmer_id ||
      queryStringObj?.activity_type ||
      queryStringObj?.status ||
      queryStringObj?.plant_type_id ||
      queryStringObj?.start_date ||
      queryStringObj?.end_date
    ) {
      const data = {
        ...initialFilterTable,
        mitra_id: queryStringObj?.mitra_id,
        farmer_id: queryStringObj?.farmer_id,
        activity_type: queryStringObj?.activity_type,
        status: queryStringObj?.status,
        start_date: queryStringObj?.start_date,
        end_date: queryStringObj?.end_date,
        plant_type_id: queryStringObj?.plant_type_id
      }
      if (data) {
        setFilterOn(true)
        setInitialValue(data)
        setTypeId(data?.plant_type_id ?? "")
        setMitra(data?.mitra_id ?? "")
        setFarmer(data?.farmer_id ?? "")
        if (data?.activity_type) {
          setTypeValue(data?.activity_type.split(","))
        }
        if (data?.status) {
          setTypeValueStatus(data?.status.split(","))
        }
        if (data?.end_date && data.start_date) {
          let selectedDate =
            dateSelect(format(new Date(data.start_date), DATE_TYPE_ENUM.ISO_DATE_STRAP)) +
            " s/d " +
            dateSelect(format(new Date(data.end_date), DATE_TYPE_ENUM.ISO_DATE_STRAP))
          setDateRange(selectedDate)
          setState([
            {
              startDate: new Date(data?.start_date),
              endDate: new Date(data?.end_date),
              key: "selection"
            }
          ])
        } else {
          setDateRange("")
        }
      }
    }
  }, [queryStringObj, dispatch])

  function onChangeMitra(e) {
    setMitra(e)
  }

  function onChangeFarmer(e) {
    setFarmer(e)
  }

  function handleType(data) {
    const { name, checked } = data.target

    if (checked) {
      setTypeValue([...typeValue, name])
    } else {
      let filteredStatus = typeValue.filter((item) => item !== name)
      setTypeValue(filteredStatus)
    }
  }

  function handleOpenCloseFilter(value) {
    setShowFilter(value)
  }

  function filterOnReset() {
    setDateRange("")
    setShowFilter(false)
    setFilterOn(false)
    setFarmer("")
    setMitra("")
    setTypeId("")
    setTypeValue([])
    setTypeValueStatus([])
    setState({
      startDate: "",
      endDate: "",
      key: "selection"
    })
    setInitialValue({
      ...initialFilterTable,
      mitra_id: "",
      activity_type: "",
      status: "",
      start_date: "",
      end_date: "",
      plant_type: "",
      farmer_id: ""
    })
    setShowFilter(false)
    setFilterOn(false)

    setInitialValue({
      activity_type: "",
      status: "",
      mitra_id: "",
      farmer_id: "",
      plant_type_id: "",
      start_date: "",
      end_date: ""
    })
    resetDate()

    setTypeValue([])
    let param = {
      ...initialFilterTable,
      activity_type: "",
      status: "",
      mitra_id: "",
      farmer_id: "",
      plant_type_id: "",
      start_date: "",
      end_date: "",
      keyword: search.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function submitLog() {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, DATE_TYPE_ENUM.ISO_DATE_STRAP)) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, DATE_TYPE_ENUM.ISO_DATE_STRAP))
    setDateRange(selectedDate)
    setOpenDate(false)
  }

  function resetDate() {
    setOpenDate(false)
    setDateRange("")
    setState([
      {
        startDate: "",
        endDate: "",
        key: "selection"
      }
    ])
  }

  function dateRangePicker() {
    return (
      <div style={{ transform: `translateY(23px)` }}>
        <div className={"date-picker-custom "} style={{ fontSize: 11 }}>
          <DateRange
            className={"font-10"}
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
          <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
            <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
              Reset Tanggal
            </button>
            <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
              Terapkan
            </button>
          </div>
        </div>
      </div>
    )
  }

  function selectDate() {
    return (
      <div className={"d-flex justify-content-end"}>
        <div
          onClick={() => setOpenDate(!openDate)}
          className={
            "select-date d-flex justify-content-between align-items-center cursor-pointer"
          }>
          <label className={"mb-0 txt-12 cursor-pointer"}>
            {dateRange ? dateRange : "Pilih Range Tanggal"}
          </label>
          <CalenderSvgIcon />
        </div>
      </div>
    )
  }

  function filterComponent() {
    return (
      <>
        <Card className="card-custom card-shadowless ">
          <CardHeader title={t("label.farmer")} className="px-0 min-h-2" />
          <CardBody className="px-0 py-5">
            <RowModule>
              <SelectInput
                name="farmer_id"
                onChange={onChangeFarmer}
                editable={!isReadOnly}
                label="Nama Petani"
                placeholder="Pilih Salah Satu"
                options={stateFarmer}
                withoutFeedback={true}
                clearNow={true}
                value={farmer}
              />
            </RowModule>
          </CardBody>
        </Card>

        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.mitra")} className="px-0 min-h-2" />
          <CardBody className="px-0 py-5">
            <RowModule>
              <SelectInput
                name="mitra_id"
                onChange={onChangeMitra}
                editable={!isReadOnly}
                label="Nama Mitra"
                placeholder="Pilih Salah Satu"
                options={stateMitra}
                withoutFeedback={true}
                clearNow={true}
                value={mitra}
              />
            </RowModule>
          </CardBody>
        </Card>
        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.plant_type")} className="px-0" />
          <CardBody className="px-0">
            {statePlantType.map((item) => (
              <RadioButtonGroup
                editable={false}
                key={item.value}
                onClick={() => {
                  setTypeId(item.value)
                }}
                checked={typeId == item.value}
                name="plant_type"
                label={item.label}
                value={item.value}
              />
            ))}
          </CardBody>
        </Card>
        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.activity_type")} className="px-0" />
          <CardBody className="px-0">
            <CheckboxLabelButton
              formInitialValues={typeValue}
              isStatus={isType}
              name={"activity_type"}
              innerRef={formRef}
              handleChange={handleType}
            />
          </CardBody>
        </Card>

        <Card className="card-custom card-shadowless">
          <CardHeader title={t("label.activity_date")} className="px-0 min-h-2" />
          <CardBody className="px-0 py-5">
            {selectDate()}
            {openDate && dateRangePicker()}
          </CardBody>
        </Card>
      </>
    )
  }

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={"Masukkan nama lahan / petani"}
        onSubmitForm={searchFunc}
        initialValues={search}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent()}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>
      <RowModule>
        <TabsWithIcon
          changeTab={changeTabs}
          initValue={tabActive}
          labelTab={getTabContents().labels}
          componentTab={getTabContents().contents}
        />
      </RowModule>
    </>
  )
}
