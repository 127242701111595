import { useHtmlClassService } from "../../../../../_metronic/layout/index.js"
import React, { useEffect, useState } from "react"
import { MenuBack } from "../../../../../component/atoms/MenuBack/index.jsx"
import { Stepper } from "../../../../../component/atoms/Stepper/index.jsx"
import { useTranslation } from "react-i18next"

export const CreateReportBaPage = () => {
  const uiService = useHtmlClassService()
  const t = useTranslation()
  const [activeStep, setActiveStep] = useState(0)

  const labelStep = ["Pilih Lahan", "Isi Detail Lahan", "Unggah Berita Acara"]
  useEffect(() => {
    uiService.setFullPage()
  }, [])

  const header = (
    <div
      className={
        "bg-white d-flex justify-content-between align-content-center p-6 border-1 border-bottom border-bottom-light-dark"
      }>
      <MenuBack title={t("label.create_ba_land_clearing")} onClick={() => setShowPopupExit(true)} />
      <Stepper step={labelStep} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )

  return <div>{header}</div>
}
