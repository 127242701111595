import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"
import SVG from "react-inlinesvg"
import React from "react"
import { NavLink } from "react-router-dom"

export const AddMenuItem = ({ data, key }) => {
  return (
    <NavLink to={data?.navigateLink ?? "/"}>
      <div
        className={
          "col menu-item p-3 w-100px h-100px d-flex flex-column align-items-center justify-content-center"
        }
        key={key}>
        <SVG src={toAbsoluteUrl(data?.iconMenu)} />
        <div className={"title text-dark font-weight-normal mb-1 font-size-sm text-center"}>
          {data.title}
        </div>
      </div>
    </NavLink>
  )
}
