import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabLog, TabsComponent } from "../../../../component/index.jsx"
import { AreaTab } from "./tab/AreaTab.jsx"
import {
  cancelPlatingSeason,
  clearDataDetail,
  clearDataDetailMap,
  confirmationSeasonPlanting,
  getAreaUnassignList,
  getDetail,
  getListActivity,
  getMapDetail,
  getRejectReasons,
  updateImage,
  updateStatus,
  updateUnassign
} from "../../../../redux/actions/AreaAction.jsx"
import { clearUploadAction } from "../../../../redux/actions/MasterDataAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Button } from "react-bootstrap"
import {
  FlatIconAndText,
  FlatIconAndText2Row
} from "../../../../component/atoms/FlaticonAndText/index.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { Popup, PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { FarmerRejectReasons } from "../../../../component/molecule/List/FarmerRejectReasons.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { FarmerUnassignReasons } from "../../../../component/molecule/List/FarmerUnassignReasons.jsx"
import { AREA, ENUM_LOG } from "../../../../constants/InitTypeConstants.js"
import { checkValue, toMeter } from "../../../helpers/TextHelper.js"
import { Warning } from "../../../../component/atoms/index.jsx"
import { ButtonDropdown } from "../../../../component/atoms/Button/ButtonDropdown.jsx"
import { ActivityTab } from "./tab/ActivityTab.jsx"
import { ActivityHistoryTab } from "./tab/ActivityHistoryTab.jsx"
import { ICON_CONSTANS, ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { Skeleton } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"

export const ListAreaDetail = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { General, Area, MasterData } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [coordinat, setCoordinat] = useState([])
  const [mapAreas, setMapAreas] = useState([])
  const [agenda] = useState({})
  const [showPopupReject, setShowPopupReject] = useState(false)
  const [showPopupUnassign, setShowPopupUnassign] = useState(false)
  const [rejectReasons, setRejectReasons] = useState([])
  const [showPopupApprove, setShowPopupApprove] = useState(false)

  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const [unassignReasons, setUnassignReasons] = useState(null)
  const [swalRedirect, setSwalRedirect] = useState("")
  const [idDocument, setIdDocument] = useState(null)
  const [popUpMusimTanam, setPopUpMusimTanam] = useState(false)
  const [popUpInfo, setPopUpInfo] = useState(false)
  const [popUpInfoBody, setPopUpInfoBody] = useState("")
  const [isOpenPopupCancelPlantingSeason, setIsOpenPopupCancelPantingSeason] = useState(false)
  const [modalAfterCancelPlantingSeason, setModalAfterCancelPlantingSeason] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState()
  const [loadingMap, setLoadingMap] = useState(true)

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      } else {
        newStatus = status
      }
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname:
            status === "active"
              ? RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all")
              : "/lahan/list/" + status,
          title: newStatus
        },
        {
          title: detail.name
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPDATE_STATUS") {
        swal({
          text: t("messages.response.success_approve_area"),
          icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.AREA_SUCCESS),
          buttons: {
            detail: {
              text: "Lihat Detail",
              className: "sweet-outline-success"
            },
            okey: { text: "Ok", className: "sweet-success" }
          }
        }).then((value) => {
          switch (value) {
            case "detail":
              history.push(swalRedirect)
              break
            default:
              true
          }
        })
        history.push("/lahan/list/pending")
      } else if (General.actionInitType === "UPDATE_STATUS_FARMER") {
        swal({
          text: swalMessage,
          icon: toAbsoluteUrl(swalIcon)
        })
        history.push(RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"))
      } else if (General.actionInitType === "UPLOAD") {
        const indexIdDocument = idDocument ?? 0
        const data = {
          area_document_id: detail?.link_document_area[indexIdDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateImage({ id, data }))
      } else if (General.actionInitType === "UPDATE_IMAGE") {
        dispatch(getDetail(id))
        dispatch(getMapDetail(id))
      } else if (General.actionInitType === AREA.PLANTING_SEASON_CONFIRMATION) {
        swal({
          text: Area.confirm,
          icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLANTING_SEASON_CONFIRMATION)
        }).then(() => {
          dispatch(getDetail(id))
          dispatch(getMapDetail(id))
        })
      } else if (General.actionInitType === AREA.CANCEL_PLANTING_SEASON) {
        setModalAfterCancelPlantingSeason(true)
      }
    }
  }, [General])

  const removeAssign = () => {
    if (detail?.is_hst) {
      setPopUpInfo(true)
      setPopUpInfoBody("Petani tidak dapat di hapus karena masih ada aktivitas tanam.")
    } else {
      setSwalMessage("Petani dihapus dari lahan. Data lahan berhasil diperbarui")
      setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.AREA_SUCCESS))
      setShowPopupUnassign(true)
    }
  }
  const konfirmasiMusimTanam = () => {
    if (detail?.is_harvest) {
      setPopUpMusimTanam(true)
    } else {
      setPopUpInfo(true)
      setPopUpInfoBody(
        "Musim tanam tidak bisa dikonfirmasi karena belum melakukan aktivitas panen."
      )
    }
  }

  const [btnDropdownList, setBtnDropdown] = useState([])

  useEffect(() => {
    const dropdonwList = []
    if (detail?.farmer_response?.id) {
      dropdonwList.push({
        title: "Remove Petani",
        icon: ICON_CONSTANS.ACTION.IC_FARMER,
        action: removeAssign,
        showed: true
      })
    }
    if (status === "active" && detail?.has_allowed_confirm_planting_season) {
      dropdonwList.push({
        title: "Konfirmasi Musim Tanam",
        icon: ICON_CONSTANS.ACTION.IC_HARVEST,
        action: konfirmasiMusimTanam,
        showed: privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1
      })
    }
    if (status === "active" && detail?.has_allowed_cancel_planting_season) {
      dropdonwList.push({
        title: "Batal Musim Tanam",
        icon: ICON_CONSTANS.ACTION.IC_SUSPEND,
        action: openModalCancelPlantingSeason,
        showed: privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1
      })
    }
    if (status === "active" && detail?.has_allowed_failed_harvest) {
      dropdonwList.push({
        title: "Gagal Panen",
        icon: ICON_CONSTANS.ACTION.IC_BULLET_CLOSE_OUTLINE,
        action: failedHarvest,
        showed: privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1
      })
    }
    setBtnDropdown(dropdonwList)
  }, [status, detail])

  function failedHarvest() {
    history.push(RoutesConstants.AREA.FORM_FAILED_HARVEST(id))
  }

  function openModalCancelPlantingSeason() {
    setIsOpenPopupCancelPantingSeason(true)
  }

  useEffect(() => {
    switch (status) {
      case "active":
        subHeader.setButton(<ButtonDropdown title={"Action"} dropdownList={btnDropdownList} />)
        break
      case "reject":
        subHeader.setButton(null)
        break

      case "pending":
        if (privileges.indexOf(PRIVILEGE_ENUM.APPROVAL_AREA_REGISTRATION) > -1) {
          subHeader.setButton(
            <RowModule>
              <>
                <Button
                  variant="danger"
                  onClick={() => {
                    setShowPopupReject(true)
                    setSwalMessage("Lahan berhasil ditolak")
                    setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.AREA_REJECT))
                    setSwalRedirect("/lahan/list/reject/" + id)
                  }}>
                  Reject
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => {
                    setShowPopupApprove(true)
                    setSwalRedirect("/lahan/list/active/" + id)
                    setSwalMessage("Lahan berhasil diverifikasi")
                    setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.AREA_SUCCESS))
                  }}>
                  Approve
                </Button>
              </>
            </RowModule>
          )
        }
        break
      case "lead":
        subHeader.setButton(
          <Button
            variant="danger"
            onClick={() => {
              history.push("/area/action/edit/" + id)
            }}>
            Delete
          </Button>
        )
        break

      default:
        break
    }
  }, [status, detail, btnDropdownList])

  useEffect(() => {
    if (status === "pending") {
      dispatch(getRejectReasons())
    } else if (status === "active") {
      if (!detail.is_hast) {
        dispatch(getAreaUnassignList())
      }
    }
  }, [])

  useEffect(() => {
    dispatch(getDetail(id))
    dispatch(getMapDetail(id))
    dispatch(clearUploadAction())

    return () => {
      dispatch(clearDataDetail())
      dispatch(clearDataDetailMap())
    }
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area.data])

  useEffect(() => {
    if (detail.coordinate) {
      setCoordinat(
        detail.coordinate.map((item) => {
          return {
            lat: item.latitude,
            lng: item.longitude
          }
        })
      )
    }
  }, [detail.coordinate])

  useEffect(() => {
    if (Area.mapDetail) {
      setLoadingMap(false)
      setMapAreas(Area.mapDetail)
    }
  }, [Area.mapDetail])

  const landArea = (data, category) => {
    return (
      <div>
        {toMeter(checkValue(data))} ({category})
      </div>
    )
  }

  const descriptionLeft = (data) => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={landArea(data?.land_area, "Manual")}
            svg={ICON_CONSTANS.IC_FIELD_2}
          />
          <FlatIconAndText
            text={landArea(data?.estimated_land_area, "Polygon")}
            svg={ICON_CONSTANS.IC_FIELD_2}
          />
          <FlatIconAndText text={data?.soil_type ?? "-"} svg={ICON_CONSTANS.IC_SOIL_ALT} />
          <FlatIconAndText text={data?.soil_dept ?? "-"} svg={ICON_CONSTANS.IC_SOIL} />
          <FlatIconAndText text={data?.irrigation ?? "-"} svg={ICON_CONSTANS.IC_WATER_IRRIGATION} />
        </div>
        {General.loading ? (
          <>
            <Skeleton animation="wave" variant={"text"} width={250} />
            <Skeleton animation="wave" variant={"text"} width={200} />
          </>
        ) : (
          <>
            <span className="font-weight-bold text-dark-50">
              {data?.sub_district_name + " - " + data?.district_name}
            </span>
            <span className="font-weight-bold text-dark-50">
              {data?.regency_name + " - " + data?.province_name}
            </span>
            <Warning potential_issue={data?.potential_issue} title={"Potensi Bahaya Lahan"} />
          </>
        )}
      </>
    )
  }

  const flatIconAndText2Rows = (data) => {
    return (
      <>
        <FlatIconAndText2Row
          rounded={true}
          title="Nama Petani"
          description={data?.farmer_response?.name}
          svg={ICON_CONSTANS.IC_FARMER_BIG}
          status={status}
          is_hst={data?.is_hst}
        />
        <FlatIconAndText2Row
          rounded={true}
          title="Kepemilikan"
          description={data?.land_ownership ?? "-"}
          svg={ICON_CONSTANS.IC_DOCUMENT}
        />
        <FlatIconAndText2Row
          rounded={true}
          title="Nama Mitra"
          description={data?.mitra_profile_response?.name}
          svg={ICON_CONSTANS.IC_MITRA_2}
        />
        <FlatIconAndText2Row
          rounded={true}
          title="Musim Tanam Selesai"
          description={data?.planting_season_finish_count + " Musim Panen"}
          svg={ICON_CONSTANS.IC_HARVEST}
        />
        {data?.total_failed_harvest > 0 && (
          <FlatIconAndText2Row
            rounded={true}
            title="Gagal Panen"
            description={data?.total_failed_harvest + " Kali"}
            svg={ICON_CONSTANS.IC_FAILUR_HARVEST}
          />
        )}
      </>
    )
  }

  const getTabContents = () => {
    const labelList = ["DETAIL LAHAN"]
    const componentList = [
      <AreaTab
        key={0}
        detail={detail}
        titleCardDetail={detail.name}
        descriptionLeft={descriptionLeft(detail)}
        flatIconAndText2Rows={flatIconAndText2Rows(detail, agenda)}
        documentLahan={detail.link_document_area}
        coordinate={coordinat}
        zoom={15}
        rejectReason={detail?.reason_rejection}
        setIdDocument={setIdDocument}
        mapAreas={mapAreas}
        loadingMap={loadingMap}
        status={status}
      />
    ]

    if (status === "active") {
      if (privileges.indexOf(PRIVILEGE_ENUM.TAB_ACTIVITY) > -1) {
        labelList.push("AKTIVITAS")
        componentList.push(<ActivityTab id={id} status={status} history={history} />)
      }

      if (privileges.indexOf(PRIVILEGE_ENUM.TAB_ACTIVITY_HISTORY) > -1) {
        labelList.push("RIWAYAT AKTIVITAS")
        componentList.push(<ActivityHistoryTab id={id} history={history} />)
      }
    }

    if (status) {
      labelList.push("LOG")
      componentList.push(<TabLog historyTabEnum={ENUM_LOG.AREA} id={id} />)
    }

    return {
      labels: labelList,
      contents: componentList
    }
  }

  const areaUpdateStatus = async (status = "") => {
    dispatch(updateStatus(id, status.toUpperCase(), rejectReasons))
  }

  const areaUpdateUnassign = async () => {
    dispatch(updateUnassign(id, unassignReasons, otherReasonText))
  }

  const modulPopUpMusimTanam = (
    <PopupQuestion
      show={popUpMusimTanam}
      title="KONFIRMASI MUSIM TANAM"
      bodyCustom={
        <>
          <span>Apakah anda yakin mengkonfirmasi musim tanam?</span>
          <span>Informasi aktivitas akan dipindahkan ke tab </span>
          <span className="font-weight-bold">Riwayat Aktivitas.</span>
        </>
      }
      textOk="Lanjut"
      onCancel={() => {
        setPopUpMusimTanam(false)
      }}
      onOk={() => {
        dispatch(confirmationSeasonPlanting(id))
        setPopUpMusimTanam(false)
      }}
    />
  )

  const modulPopUpInfo = (
    <Popup
      show={popUpInfo}
      body={popUpInfoBody}
      textOk="Ok"
      onClick={() => {
        setPopUpInfo(false)
      }}
      type={"gagal"}
    />
  )

  function PopupCancelPlantingSeasonBody() {
    return (
      <div className={"text-center"}>
        Dengan membatalkan musim tanam, status tanam lahan yang terdaftar akan menjadi{" "}
        <strong>Inactive.</strong> Pastikan lahan yang dipilih telah benar.
      </div>
    )
  }

  function bodyAfterCancelPlantingSeason() {
    return (
      <div className={"text-center d-flex align-items-center flex-column"}>
        <img
          style={{ width: 160 }}
          src={
            General.actionInitType === AREA.CANCEL_PLANTING_SEASON && General.isError
              ? ILUSTRATION_CONSTANS.FAIL
              : ILUSTRATION_CONSTANS.SUCCESS_FAILED_HARVEST
          }
        />
        {General.actionInitType === AREA.CANCEL_PLANTING_SEASON && General.isError ? (
          <div className={"mt-4"}>
            Lahan tidak dapat dinyatakan <strong>Batal Musim Tanam</strong> karena lahan masih
            memiliki kegiatan bersama Maxxitani. Mohon diperiksa kembali.
          </div>
        ) : (
          <div className={"mt-4"}>Lahan berhasil dinyatakan batal musim tanam.</div>
        )}
      </div>
    )
  }

  const modulPopUpRemoveFarmer = (
    <PopupQuestion
      show={showPopupUnassign}
      title="HAPUS PETANI Dari lahan"
      bodyCustom={
        <FarmerUnassignReasons
          items={Area?.rejectReasons}
          name={"farmerUnassign"}
          onChange={(value) => {
            setUnassignReasons(value.enum_name)
            setOtherReasonText(value.reason)
          }}
        />
      }
      textOk="Submit"
      onCancel={() => {
        setShowPopupUnassign(false)
      }}
      disable={unassignReasons && unassignReasons.length > 0 ? false : true}
      onOk={() => {
        areaUpdateUnassign()
        setShowPopupUnassign(false)
      }}
    />
  )

  const modulPopUpReject = (
    <PopupQuestion
      show={showPopupReject}
      title="LAHAN TIDAK SESUAI KRITERIA"
      bodyCustom={
        <FarmerRejectReasons
          items={Area?.rejectReasons}
          onChange={(list) => {
            setRejectReasons(list)
          }}
        />
      }
      textOk="Reject"
      onCancel={() => {
        setShowPopupReject(false)
      }}
      disable={rejectReasons.length > 0 ? false : true}
      onOk={() => {
        areaUpdateStatus("REJECTED")
        setShowPopupReject(false)
      }}
    />
  )

  const modulPopUpApprove = (
    <PopupQuestion
      show={showPopupApprove}
      title="LAHAN SESUAI KRITERIA"
      bodyCustom={
        <span>
          Data Lahan yang disetujui akan berubah menjadi{" "}
          <span className="font-weight-bold">terverifikasi</span>. Pastikan semua data telah lengkap
          dan benar.
        </span>
      }
      textOk="Approve"
      onCancel={() => {
        setShowPopupApprove(false)
      }}
      onOk={() => {
        areaUpdateStatus("ACTIVE")
        setShowPopupApprove(false)
        setShowPopup({
          show: true,
          body: "Petani berhasil diverifikasi"
        })
      }}
    />
  )

  return (
    <>
      {modulPopUpMusimTanam}
      {modulPopUpInfo}
      {modulPopUpRemoveFarmer}
      {modulPopUpReject}
      {modulPopUpApprove}
      <PopupQuestion
        show={isOpenPopupCancelPlantingSeason}
        bodyCustom={PopupCancelPlantingSeasonBody()}
        textOk="Lanjut"
        textCancel={"Batal"}
        onCancel={() => setIsOpenPopupCancelPantingSeason(false)}
        closeButton={true}
        title={"BATAL MUSIM TANAM"}
        onOk={() => {
          dispatch(cancelPlatingSeason(id))
          setIsOpenPopupCancelPantingSeason(false)
        }}
      />
      <Popup
        show={modalAfterCancelPlantingSeason}
        bodyCustom={bodyAfterCancelPlantingSeason()}
        textOk="Ok"
        variantCancelButton={"outline-primary"}
        onClick={() => {
          setModalAfterCancelPlantingSeason(false)
          setTimeout(() => {
            dispatch(getDetail(id))
            dispatch(getMapDetail(id))
            dispatch(getListActivity(id))
          }, 200)
        }}
      />
      <TabsComponent labelTab={getTabContents().labels} componentTab={getTabContents().contents} />
    </>
  )
}