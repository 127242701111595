import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, NoteView, TableSkeleton, TableView } from "../../../../../component/index.jsx"
import {
  ICON_CONSTANS,
  ILLUSTRATION_ASSETS_V2,
  ILUSTRATION_CONSTANS
} from "../../../../../constants/ImageConstants.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret, toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { FloatingSelectArea } from "../../../../../component/atoms/FloatingSelectArea/index.jsx"
import { useTranslation } from "react-i18next"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { checkValue, toMeter } from "../../../../helpers/TextHelper.js"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { DATE_TYPE_ENUM } from "../../../../../constants/StringConstant.js"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import SVG from "react-inlinesvg"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { bulkConfirmationPlantingSeason } from "../../../../../redux/actions/AreaAction.jsx"
import { useDispatch, useSelector } from "react-redux"
import { AREA } from "../../../../../constants/InitTypeConstants.js"
import swal from "sweetalert"
import { useLocation } from "react-router"

export const Table = ({ data, changePage, history, general, isFilter, fetchData }) => {
  const { t } = useTranslation()
  const privileges = loadUserLogin().privilege
  const dispatch = useDispatch()
  const location = useLocation()
  const { General, Area } = useSelector((state) => state)

  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const uiArea = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">
          {e?.sub_district_name}, {e?.land_area ? toMeter(e?.land_area) : "-"}
        </span>
      </>
    )
  }

  const uiMitra = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name}</a>
        <span className="text-muted font-weight-bold d-block">{e?.mitra_phone}</span>
      </>
    )
  }

  const uiFarmer = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.farmer_name}</a>
        <span className="text-muted font-weight-bold d-block">{e.farmer_phone}</span>
      </>
    )
  }

  const uiPlant = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.plant_type}</a>
        <span className="text-muted font-weight-bold d-block">{e?.variety}</span>
      </>
    )
  }

  const uiHarvest = (e) => {
    return (
      <div>
        <div>
          {e?.harvest_date ? timeStampToDate(e?.harvest_date, DATE_TYPE_ENUM.ISO_DATE_STRAP) : "-"}
        </div>
        <div>{checkValue(e?.hst, "HST")}</div>
      </div>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
          <div
            className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
            onClick={() => {
              history.push(RoutesConstants.AREA.DETAIL_AREA("active", e?.area_id))
            }}>
            <DetailSvgIcon />
          </div>
        </OverlayTrigger>
      </>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: t("table_header.area_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiArea
      },
      sortCaret: sortCaret,
      sort: false,
      headerStyle: () => {
        return { width: "20%" }
      }
    },
    {
      dataField: "mitra_name",
      text: t("table_header.mitra"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      },
      sortCaret: sortCaret,
      sort: false
    },

    {
      dataField: "farmer_name",
      text: t("table_header.farmer"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      },
      sortCaret: sortCaret,
      sort: false
    },
    {
      dataField: "plant_type",
      text: t("table_header.plant_type"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlant
      },
      sort: false
    },
    {
      dataField: "activityDate",
      text: t("table_header.harvest_date"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiHarvest
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "action",
      text: t("table_header.action"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  const textInfo = (
    <div className={"d-flex align-items-center text-center"}>
      <SVG className="mr-2" src={ICON_CONSTANS.IC_NOTE_VIEW_WARNING_TRIANGEL} />
      <div className={`text-warning`}>{t("messages.alert.max_select_area")}</div>
    </div>
  )

  const handleBulkConfirmationPlantingSeason = () => {
    if (selectedRows.length) {
      const body = {
        planting_season_ids: selectedRows.map((item) => item?.planting_season_id)
      }
      dispatch(bulkConfirmationPlantingSeason(body))
    }
  }

  useEffect(() => {
    if (
      !General.loading &&
      General.actionInitType === AREA.BULK_CONFIRMATION_PLANTING_SEASON &&
      Area.responsePost
    ) {
      swal({
        text: Area.responsePost,
        icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLANTING_SEASON_CONFIRMATION)
      }).then(() => {
        const result = location?.search
        fetchData(result)
      })
    }
  }, [General, Area.responsePost])

  const messagePopupConfirmation = (
    <div className={"text-center"}>
      <div>{t("messages.confirmation.bulk_planting_season_confirmation")}</div>
      <div>
        <b> {t("messages.confirmation.bulk_planting_season_confirmation_validation")}</b>
      </div>
    </div>
  )

  return (
    <>
      {/* Popup for approve */}
      <PopupQuestion
        show={openConfirmation}
        title={t("messages.title.bulk_planting_season_confirmation")}
        body={messagePopupConfirmation}
        textOk={t("button.continue")}
        variantOkButton={"primary"}
        onCancel={() => {
          setOpenConfirmation(false)
        }}
        onOk={() => {
          setOpenConfirmation(false)
          setSelectedRows([])
          handleBulkConfirmationPlantingSeason()
        }}
        textCancel={t("button.cancel")}
      />

      {selectedRows.length > 0 && !openConfirmation && (
        <FloatingSelectArea
          totalArea={selectedRows.length}
          buttonlabel={t("label.confirmation_planting_season_bulk")}
          withIcon={true}
          labelIcon={ICON_CONSTANS.IC_LAND_AREA_WHITE}
          handleClick={() => {
            setOpenConfirmation(true)
          }}
          buttonColor={"primary"}
          disableButton={selectedRows.length > 20 ? true : false}
          showInfo={selectedRows.length > 20 ? true : false}
          infoText={textInfo}
        />
      )}
      <Card>
        <CardBody>
          <NoteView
            variant={"warning"}
            icon={ICON_CONSTANS.IC_NOTE_VIEW_WARNING_TRIANGEL}
            title={
              <>
                <div>
                  Lahan yang ada memiliki <b>laporan panen dan telah dipanen seluruhnya.</b>
                </div>
                <div>Pastikan lahan yang akan dikonfirmasi sudah lunas pembayaran.</div>
              </>
            }
          />
          {general.loading ? (
            <TableSkeleton column={8} row={10} />
          ) : data?.pageData?.length === 0 ? (
            <EmptyCard
              image={
                isFilter
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_DATA
              }
              body={
                isFilter
                  ? t("messages.response.response_not_found_data")
                  : t("messages.response.empty_state", { name: "lahan" })
              }
              subtitle={isFilter ? t("messages.response.sub_response_not_found_data") : ""}
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={data?.pageData ?? []}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            currentPage={data?.page?.currentPage}
            currentElement={data?.page?.currentElement}
            totalCount={data?.page?.totalCount}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            keyField={"id"}
            selectable={privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1}
            onSelect={(row, isSelected) => {
              let rows = JSON.parse(JSON.stringify(selectedRows))
              if (isSelected) {
                rows.push(row)
              } else {
                rows = rows.filter((r) => r.id !== row.id)
              }
              setSelectedRows(rows)
            }}
            onSelectAll={(isSelect, rows) => {
              let rowses = JSON.parse(JSON.stringify(selectedRows))
              if (isSelect) {
                if (rows?.length > 0) {
                  rows?.map((e) => {
                    rowses.push(e)
                  })
                }
              } else {
                if (rows?.length) {
                  let rowsesNew = rowses
                  rows?.map((row) => {
                    const removeIndex = rowses.findIndex((e) => e?.id === row?.id)
                    rowsesNew.splice(removeIndex, 1)
                  })
                  rowses = rowsesNew
                }
              }
              setSelectedRows(rowses)
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}