import { getUrlParsingPageFilter, getUrlParsingStatus } from "../../config/EndpointCollection.js"
import { GeneralSlice } from "../reducers/General"
import { FARMER } from "../../constants/InitTypeConstants"
import { requestDelete, requestGet, requestPost, requestPut } from "../../config"
import { FARMER_URL } from "../../constants/UrlConstants"
import { FarmerSlice } from "../reducers/Farmer"

const { actions: general } = GeneralSlice
const { actions: farmer } = FarmerSlice

export function getListFarmerV2(tab, param) {
  return async (dispatch) => {
    // let urlData = getUrlParsingPageFilter(param.pageReq);
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_FARMER_PER_STATUS(tab) + (param || ""))
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataActive = (param) => {
  return async (dispatch) => {
    // let urlData = getUrlParsingPageFilter(param.pageReq);
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_ACTIVE_PAGE + param)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    farmer.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export const getPageDataActiveAll = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_ACTIVE_ALL + param)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataActiveNonKur = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_ACTIVE_NON_KUR + param)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataActiveKur = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_ACTIVE_KUR + param)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataPending = (param) => {
  return async (dispatch) => {
    // let urlData = getUrlParsingPageFilter(param.pageReq);
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_PENDING_PAGE + param)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataRejected = (param) => {
  return async (dispatch) => {
    // let urlData = getUrlParsingPageFilter(param.pageReq);
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_REJECTED_PAGE + param)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataLead = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_LEAD_PAGE + urlData)
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetail = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(general.setLoading({ loading: true, actionInitType: FARMER.GET_DETAIL }))
      try {
        let res = await requestGet(FARMER_URL.GET_LIST_DETAIL + param)
        dispatch(farmer.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FARMER.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getDetailLead = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(general.setLoading({ loading: true, actionInitType: FARMER.GET_DETAIL }))
      try {
        let res = await requestGet(FARMER_URL.GET_LIST_DETAIL_LEAD + param)
        dispatch(farmer.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FARMER.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const createFarmer = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(general.setLoadingGlobal({ loading: true, actionInitType: FARMER.CREATE }))
      try {
        let res = await requestPost(FARMER_URL.CREATE_FARMER_V2, data)
        dispatch(farmer.setMessages(res.data))
        dispatch(general.setLoadingGlobal({ loading: false, actionInitType: FARMER.CREATE }))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CREATE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const createFarmerBulk = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FARMER.CREATE_BULK
        })
      )
      try {
        await requestPost(FARMER_URL.CREATE_FARMER_V2_BULK, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FARMER.CREATE_BULK
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CREATE_BULK
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const createFarmerBulkLead = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FARMER.CREATE_BULK_LEAD
        })
      )
      try {
        await requestPost(FARMER_URL.CREATE_FARMER_V2_BULK_LEAD, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FARMER.CREATE_BULK_LEAD
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CREATE_BULK_LEAD
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updateFarmer = (id, data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(general.setLoadingGlobal({ loading: true, actionInitType: FARMER.UPDATE }))
      try {
        let res = await requestPut(FARMER_URL.CREATE_FARMER_V2 + "/" + id, null, data)
        dispatch(farmer.setMessages(res.data))
        dispatch(general.setLoadingGlobal({ loading: false, actionInitType: FARMER.UPDATE }))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CREATE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getFarmerByMitra = (param) => {
  return async (dispatch) => {
    // let urlData = getUrlParsingPage(param.pageReq);
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_ALL_FOR_COMBOBOX
      })
    )
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_BY_MITRA + param.id + param?.pageReq ?? "")
      dispatch(
        farmer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_ALL_FOR_COMBOBOX
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getRejectReasons = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.GET_REJECT_REASON
      })
    )
    try {
      let res = await requestGet(FARMER_URL.GET_REJECT_REASON)
      dispatch(farmer.getRejectReason(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.GET_REJECT_REASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const updateStatus = (id, status, reasons = []) => {
  const payload = {
    status,
    reject_enum_list: reasons
  }
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: FARMER.UPDATE_STATUS
      })
    )
    try {
      let res = await requestPut(FARMER_URL.UPDATE_STATUS(id), null, payload)
      dispatch(farmer.setMessages(res.data))
      dispatch(farmer.getRejectReason(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: FARMER.UPDATE_STATUS
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: FARMER.UPDATE_STATUS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreas = (id) => {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: FARMER.GET_AREAS }))
    try {
      let res = await requestGet(FARMER_URL.GET_AREAS(id))
      dispatch(farmer.getAreas(res.data))
      dispatch(general.setLoading({ loading: false, actionInitType: FARMER.GET_AREAS }))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDownloadTemplateExcel = () => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: FARMER.GET_DOWNLOAD }))
    try {
      let res = await requestGet(FARMER_URL.GET_DOWNLOAD_EXCEL)
      dispatch(farmer.getUrlDownloadExcel(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: FARMER.GET_DOWNLOAD
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const createCalonFarmer = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FARMER.CREATE_CALON
        })
      )
      try {
        await requestPost(FARMER_URL.CREATE_CALON_FARMER, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FARMER.CREATE_CALON
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CREATE_CALON
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const removeLead = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(general.setLoadingGlobal({ loading: true, actionInitType: FARMER.DELETE }))
      try {
        await requestDelete(FARMER_URL.REMOVE_LEAD_FARMER + param)
        dispatch(general.setLoadingGlobal({ loading: false, actionInitType: FARMER.DELETE }))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CREATE_CALON
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getCheckCanDeactivate = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.CHECK_DEACTIVATE
      })
    )
    try {
      let res = await requestGet(FARMER_URL.GET_CHECK_CAN_DEACTIVATE + id)
      dispatch(farmer.getCanDeactivate(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.CHECK_DEACTIVATE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const resetCheckCanDeactivate = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FARMER.CHECK_DEACTIVATE
      })
    )
    try {
      dispatch(farmer.getCanDeactivate(null))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.CHECK_DEACTIVATE
        })
      )
    } catch (e) {
      dispatch(farmer.getCanDeactivate(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.CHECK_DEACTIVATE
        })
      )
    }
  }
}

export const deactivateStatusUser = (param, payload) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: FARMER.DEACTIVATE_FARMER
        })
      )
      try {
        let res = await requestDelete(FARMER_URL.DEACTIVATE_FARMER + param, payload)
        dispatch(farmer.setMessages(res.data.message))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FARMER.DEACTIVATE_FARMER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.DEACTIVATE_FARMER
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const changeMitra = (param, payload) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: FARMER.CHANGE_MITRA
        })
      )
      try {
        let res = await requestPut(FARMER_URL.CHANGE_MITRA + param, null, payload)
        dispatch(farmer.setMessages(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FARMER.CHANGE_MITRA
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FARMER.CHANGE_MITRA
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updateImage = ({ id, data }) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: FARMER.UPDATE_IMAGE
        })
      )
      let res = await requestPut(FARMER_URL.UPDATE_IMAGE + id, null, data)
      dispatch(farmer.setMessages(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FARMER.UPDATE_IMAGE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: FARMER.UPDATE_IMAGE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: FARMER.UPDATE_IMAGE, e }))
    }
  }
}

export const getListFarmer = () => {
  return async (dispatch) => {
    dispatch(general.setLoadingComponent({ loading: true, actionInitType: FARMER.GET_LIST }))
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_FARMER)
      dispatch(farmer.getList(res.data))
      dispatch(general.setLoadingComponent({ loading: false, actionInitType: FARMER.GET_LIST }))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getFarmerForFilterOrder = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingStatus(param)
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: FARMER.GET_LIST
      })
    )
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_FARMER_FOR_FILTER_ORDER + urlData)
      dispatch(farmer.getList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: FARMER.GET_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getFarmerForFilterOrderPascaPlanting = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: FARMER.GET_LIST
      })
    )
    try {
      let res = await requestGet(FARMER_URL.GET_LIST_FARMER_FOR_FILTER_ORDER_PASCA_PLANTING)
      dispatch(farmer.getList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: FARMER.GET_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearFarmerDetail = () => {
  return async (dispatch) => {
    dispatch(farmer.getPage(undefined))
  }
}
