import React, { useMemo, useState } from "react"
import objectPath from "object-path"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { checkIsActive, toAbsoluteUrl } from "../../../_helpers"
import { AsideSearch } from "./AsideSearch"
import { AsideMenu, sideMenuType } from "./aside-menu/AsideMenu"
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown"
import { QuickUserToggler } from "../extras/QuickUserToggler"
import { Brand } from "../brand/Brand"
import { KTUtil } from "../../../_assets/js/components/util"
import { NavLink, useLocation } from "react-router-dom"
import KTToggle from "../../../_assets/js/components/toggle"
import { addLinkParent } from "../../../../app/service/userManagementAction"
import {
  PrivilegeCollectionParent,
  QuickAccessMenu
} from "../../../../config/PrivilegeCollection.js"
import { ASIDE_MENU } from "../../../../constants/InitTypeConstants"
import { useTranslation } from "react-i18next"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"
import { AddMenu } from "../../../../component/molecule/AddMenu/AddMenu.jsx"

export function Aside() {
  const { t } = useTranslation()
  const [activeMenu, setAcitveMenu] = useState(false)
  const [showAdd, setShowAdd] = useState(false)

  // const menuParent = PrivilegeSelectedParent()
  var _toggleObject
  var _element = KTUtil.getById("kt_aside_toggle")
  var _body = KTUtil.getBody()
  const menuParent = addLinkParent()
  const menuAddList = QuickAccessMenu
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    document.body.classList.add("aside-minimize")
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(uiService.config, "aside.secondary.display"),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      asideSelfMinimizeDefault: objectPath.get(uiService.config, "aside.self.minimize.default"),
      extrasSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      extrasNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      extrasQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      extrasQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      extrasLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      extrasUserDisplay: objectPath.get(uiService.config, "extras.user.display")
    }
  }, [uiService])
  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_account",
    tabId3: "kt_aside_master",
    tabId4: "kt_aside_mitra",
    tabId5: "kt_aside_fo",
    tabId6: "kt_aside_farmer",
    tabId7: "kt_aside_lahan",
    tabId8: "kt_aside_setting",
    tabId9: "kt_aside_order",
    tabId10: "kt_aside_mac",
    tabId11: "kt_aside_add",
    tabId12: "kt_aside_submission"
  }

  const location = useLocation()

  const [activeTab, setActiveTab] = useState(getLinkSekarang)

  //Add Active Menu Ter kiri
  function getLinkSekarang() {
    if (checkIsActive(location, "/mitras/")) {
      return tabs.tabId4
    } else if (checkIsActive(location, "/field-officer/")) {
      return tabs.tabId5
    } else if (checkIsActive(location, "/farmer/")) {
      return tabs.tabId6
    } else if (checkIsActive(location, "/lahan/")) {
      return tabs.tabId7
    } else if (checkIsActive(location, "/role/")) {
      return tabs.tabId8
    } else if (checkIsActive(location, "/order/")) {
      return tabs.tabId9
    } else if (checkIsActive(location, "/mac/")) {
      return tabs.tabId10
    } else if (checkIsActive(location, "/submission/")) {
      return tabs.tabId12
    } else {
      return tabs.tabId2
    }
  }

  function getMenuNow() {
    if (checkIsActive(location, "/mitras/")) {
      return ASIDE_MENU.MITRA
    } else if (checkIsActive(location, "/field-officer/")) {
      return ASIDE_MENU.FO
    } else if (checkIsActive(location, "/farmer/")) {
      return ASIDE_MENU.FARMER
    } else if (checkIsActive(location, "/lahan/")) {
      return ASIDE_MENU.AREA
    } else if (checkIsActive(location, "/role/")) {
      return ASIDE_MENU.SETTING
    } else if (checkIsActive(location, "/account/")) {
      return ASIDE_MENU.SETTING
    } else if (checkIsActive(location, "/video/")) {
      return ASIDE_MENU.SETTING
    } else if (checkIsActive(location, "/master/")) {
      return ASIDE_MENU.MASTER
    } else if (checkIsActive(location, "/order/")) {
      return ASIDE_MENU.ORDER
    } else if (checkIsActive(location, "/mac/")) {
      return ASIDE_MENU.MAC
    } else if (checkIsActive(location, "/submission/")) {
      return ASIDE_MENU.SUBMISSION
    }
  }

  const handleTabChange = (id) => {
    setActiveTab(id)
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    )

    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace)
    }
  }

  const handleTabChangeHover = (id) => {
    if (!showAdd) {
      setActiveTab(id)
      const asideWorkspace = KTUtil.find(
        document.getElementById("kt_aside"),
        ".aside-secondary .aside-workspace"
      )
      if (asideWorkspace) {
        if (uiService.config.aside.self.minimize.default) {
          _toggleObject = new KTToggle(_element, _body, {
            targetState: "aside-minimize",
            toggleState: "aside-toggle-active"
          })
          _toggleObject.toggleOff()
        }
        KTUtil.scrollUpdate(asideWorkspace)
      }
    }
  }

  const handleOnMouseLeave = () => {
    setActiveTab(getMenuNow)
    // KTCookie.setCookie('kt_aside_toggle_state', 'on');
    _toggleObject = new KTToggle(_element, _body, {
      targetState: "aside-minimize",
      toggleState: "aside-toggle-active"
    })
    if (!activeMenu) {
      _toggleObject.toggleOn()
    }
  }

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}>
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 flex-fill overflow-auto ">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {/* begin::Item */}
              <li
                className="nav-item mb-7"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Dashboard">
                <NavLink to={"/dashboard"}>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="metronic-features">Dashboard</Tooltip>}>
                    <div
                      className={`nav-link btn btn-icon btn-clean btn-lg ${
                        activeTab === tabs.tabId2 && "active"
                      }`}
                      data-toggle="tab"
                      data-target={`#${tabs.tabId2}`}
                      onClick={() => handleTabChange(tabs.tabId2)}
                      onMouseEnter={() => {
                        setActiveTab(tabs.tabId2)
                        // document.body.classList.add("aside-minimize");
                      }}
                      // onMouseLeave={handleOnMouseLeave}
                      role="tab">
                      <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Detail/ic_dashboard.svg")} />
                        <div className="txt-12 mt-3">Dashboard</div>
                      </span>
                    </div>
                  </OverlayTrigger>
                </NavLink>
              </li>
              {menuParent.map((value, index) => {
                return (
                  <li
                    key={index}
                    className="nav-item mb-7"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window">
                    <NavLink to={value?.navigateLink ?? "/"}>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="metronic-features">{t(value.menuTitle)}</Tooltip>}>
                        <div
                          className={`nav-link btn btn-icon btn-clean btn-lg ${
                            activeTab === value.parent && "active"
                          }`}
                          data-toggle="tab"
                          data-target={`#${value.parent}`}
                          onClick={() => handleTabChange(value.parent)}
                          onMouseEnter={() => handleTabChangeHover(value.parent)}
                          role="tab">
                          <span className="svg-icon svg-icon-md">
                            <SVG src={toAbsoluteUrl(value.iconMenu)} />
                            <div className="txt-12 mt-3">{t(value.menuTitle)}</div>
                          </span>
                        </div>
                      </OverlayTrigger>
                    </NavLink>
                  </li>
                )
              })}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay && layoutProps.asideSelfMinimizeToggle && (
              <>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}>
                  <span
                    className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                    id="kt_aside_toggle"
                    onClick={() => setAcitveMenu(!activeMenu)}>
                    <i className="ki ki-bold-arrow-back icon-sm" />
                  </span>
                </OverlayTrigger>
              </>
            )}
            {/* end::Aside Toggle */}
            {layoutProps.extrasSearchDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}>
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_search_toggle">
                  <span className="svg-icon svg-icon-lg">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Search */}

            {/* begin::Notifications */}
            {layoutProps.extrasNotificationsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-notifications">Notifications</Tooltip>}>
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_notifications_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window">
                  <span className="svg-icon svg-icon-lg">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Notifications */}

            {/* begin::Quick Actions */}
            {layoutProps.extrasQuickActionsDisplay && <></>}
            {/* end::Quick Actions */}

            {/* begin::Quick Panel */}
            {layoutProps.extrasQuickPanelDisplay && <></>}
            {/* end::Quick Panel */}

            {/* begin::Languages*/}
            {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
            {/* end::Languages */}

            {/*added menu*/}
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="toogle-add">{t("menu.quick_access")}</Tooltip>}>
              <div
                className={`nav-link btn btn-icon btn-clean btn-lg mb-8`}
                onClick={() => {
                  setAcitveMenu(false)
                  _toggleObject = new KTToggle(_element, _body, {
                    targetState: "aside-minimize",
                    toggleState: "aside-toggle-active"
                  })
                  _toggleObject.toggleOn()
                  handleTabChange(tabs.tabId11)
                  setShowAdd(!showAdd)
                }}
                // onMouseEnter={() => handleTabChangeHover(value.parent)}
                role="tab">
                <span
                  className={`svg-icon svg-icon-md ${
                    activeTab === tabs.tabId11 ? "active" : "grayscale"
                  }`}>
                  <SVG src={toAbsoluteUrl(ICON_CONSTANS.QUICK_ACCESS)} />
                  <div className="txt-12 mt-3">{t("menu.quick_access")}</div>
                </span>
              </div>
            </OverlayTrigger>
            {/*end added menu*/}

            {<AddMenu show={showAdd} setShowAdd={setShowAdd} data={menuAddList} />}

            {/* begin::user*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::user */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div
              className="aside-secondary d-flex flex-row-fluid "
              onMouseLeave={handleOnMouseLeave}>
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  <AsideSearch isActive={activeTab === tabs.tabId1} />
                  <AsideMenu
                    isActive={activeTab === tabs.tabId2}
                    sideMenu={sideMenuType.menuAccount}
                  />

                  <AsideMenu
                    isActive={activeTab === tabs.tabId3}
                    sideMenu={sideMenuType.defaultSide}
                  />
                  <AsideMenu
                    isActive={activeTab === "MITRA"}
                    sideMenu={sideMenuType.menuMitra}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "MITRA").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "FARMER"}
                    sideMenu={sideMenuType.menuFarmer}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "FARMER").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "FO"}
                    sideMenu={sideMenuType.menuFieldOfficer}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "FO").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "AREA"}
                    sideMenu={sideMenuType.menuLahan}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "AREA").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "ORDER"}
                    sideMenu={sideMenuType.menuOrder}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "ORDER").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "MAC"}
                    sideMenu={sideMenuType.menuMac}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "MAC").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "SETTING"}
                    sideMenu={sideMenuType.menuSetting}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "SETTING")
                        .menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "MASTER"}
                    sideMenu={sideMenuType.menuMaster}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "MASTER").menuTitle
                    }
                  />
                  <AsideMenu
                    isActive={activeTab === "SUBMISSION"}
                    sideMenu={sideMenuType.menuSubmission}
                    menuTitle={
                      PrivilegeCollectionParent.find((value) => value.parent === "SUBMISSION")
                        .menuTitle
                    }
                  />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  )
}
