export const MASTER_URL = {
  GET_PROVINCE: "/v1/province",
  GET_PROVINCE_BY_REGIONAL: "/cms/v1/regional/province/list",
  GET_REGENCY: "/v1/regency/province",
  GET_DISTRICT: "/v1/district/regency",
  GET_SUB_DISTRICT: "/subdistrict/v1/cms/district/",
  GET_BANKS: "/v1/bank",
  GET_STATUS_ACCOUNTS: "/v1/account/account-status-enum",
  UPLOAD_PHOTO: "/upload/cms/v1",
  GET_PLANTS: "/v1/plant_type",
  GET_VARIETY_PLANTS: "/plant_type/mobile/v1/",
  GET_ALL_PRIVILEGE: "/v1/role/cms/privilege/all/",
  GET_VIDEO_CATEGORIES: "v1/tutorial/video/cms/category/list",
  GET_PLANT_TYPE: "v1/plant_type",
  GET_PLANT_FORM: "v1/planting-season/activity/fertilization-form-list",
  GET_OWNER_LIST: "v1/planting-season/activity/owner-tool-list",
  GET_TREATMENT: "v1/treatment",
  GET_PACKAGE: "v1/package",
  GET_MACHINE_TYPE: "v1/product-item/cms/machine-type-list",
  GET_ACTIVITY_TYPE_LIST: "v1/planting-season/activity-type-list/cms",
  GET_LIST_VARIANT_BY_AREA: "v1/planting-season/activity/area/variety-list/",
  SYNC_ERP: "erp/sync-",
  GET_SUB_DISTRICT_FOR_ORDER: "v1/planting-season/order/list-subdistrict-filter/cms",
  GET_SUB_DISTRICT_FOR_ORDER_PASCA_PLANTING: "v1/schedule/cms/filter/subdistrict",
  SYNC_PESTICIDE: "v1/category/cms/sync-pesticide",
  GET_LEGEND_MAP: "area/v2/legend/cms",
  GET_USER_MERCHANT_FILTER: "v1/external-partner/cms/user/list/filter",
  GET_LIST_PLANT_TYPE_PAGEABLE: () => `plant_type/list/cms`,
  GET_PLANT_TYPE_USER: () => `v1/plant-type/user/cms`,
  CREATE_PLANT_TYPE: () => `v1/plant-type`,
  EDIT_PLANT_TYPE: (id) => `v1/plant-type/cms/${id}`,
  GET_LIST_REGIONAL_MASTER_DATA: () => `v1/regional/list`,
  GET_LIST_AVAILABLE_PROVINCE: () => "v1/regional/province/list",
  SUBMIT_CREATE_REGIONAL: () => `v1/regional/create`,
  GET_LIST_PROVINCE_EDIT_REGIONAL: (id) =>
    `cms/v1/regional/province-available/list?regional_id=${id}`,
  EDIT_REGIONAL_MASTER_DATA: (id) => `v1/regional/edit/${id}`,
  GET_LIST_REGIONAL_SELECTED: () => `v1/regional/list/all`,
  GET_LIST_AVAILABLE_PROVINCE_FOR_CREATE_REGIONAL: () => `cms/v1/regional/province-available/list`,
  GET_LIST_REGIONAL_ALL: () => `v1/regional/list/all`,
  GET_DETAIL_REGIONAL: (id) => `cms/v1/regional/detail/${id}`,
  GET_DETAIL_SUB_REGIONAL: (id) => `/cms/v1/regional/sub-regional/${id}`,
  GET_LIST_AVAILABLE_REGENCY_SUB_REGIONAL: (id) => `cms/v1/regional/regency-available/list/${id}`,
  GET_LIST_AVAILABLE_REGENCY_SUB_REGIONAL_EDIT: (id) =>
    `/cms/v1/regional/regency-available/list/sub-regional/${id}`,
  CREATE_SUB_REGIONAL: () => `cms/v1/regional/sub-regional/create`,
  EDIT_SUB_REGIONAL: () => `/cms/v1/regional/sub-regional/edit`,
  GET_PROVINCE_BY_REGIONAL_ID: (regionalId) =>
    `cms/v1/regional/province-available/list?regional_id=${regionalId}`,
  LIST_PROVINCE_FILTER_BY_REGIONAL: (id) => `cms/v1/regional/province/list?regional_id=${id}`,
  DELETE_SUB_REGIONAL: (id) => `cms/v1/regional/sub-regional/${id}`,
  GET_REGENCY_SUB_REGIONAL_EDIT: (subRegionalId) =>
    `bitzer/cms/v1/regional/regency-available/list/sub-regional/${subRegionalId}`,
  UPDATE_STATUS_PACKAGE: (id) => `cms/v1/package/update/status/${id}`
}

export const MASTER_DATA_URL = {
  GET_CATEGORY_ITEM: "v1/category/cms",
  GET_CATEGORY_ITEM_LIST: "v1/category/cms/",
  GET_CATEGORY_ITEM_LIST_MACHINE: "v1/product-item/cms/machine-list",
  CREATE_CATEGORY_ITEM: "v1/category",
  CREATE_TREATMENT: "cms/v1/treatment",
  GET_TREATMENT_DETAIL: "v1/treatment/",
  GET_VARIANT_BY_PLANT_TYPE: `v1/category/mobile/pesticide/`,
  UPDATE_TREATMENT: "cms/v1/treatment/",
  GET_CATEGORY_REASON: "v1/reason/category",
  GET_DETAIL_REASON: "v1/reason",
  DELETE_REASON: `v1/reason/`,
  UPDATE_REASON: `v1/reason/`,
  CREATE_REASON: "v1/reason",
  GET_PACKAGE_DETAIL: "v1/package/",
  GET_TREATMENT_PACKAGE_DETAIL: "cms/v1/package/treatment/",
  CREATE_PACKAGE: "v1/package",
  UPDATE_PACKAGE: "v1/package/",
  GET_TREATMENT_BY_PLANT_TYPE: "v1/treatment/plant-type/",
  GET_STATUS_ENUM_ACTIVITY_STATUS: "v1/planting-season/activity/cms/status-list",
  GET_PLANT_TYPE_FILTER_AREA: "v1/list-plant-type-filter/cms"
}

export const MITRA_URL = {
  GET_LIST_ALL_COMBOBOX: "mitra/cms/v1/all",
  GET_LIST_PAGE: "/v1/mitra",
  GET_LIST_PENDING_PAGE: "/mitra/cms/v1/status/pending",
  GET_LIST_REJECTED_PAGE: "/mitra/cms/v1/status/rejected",
  GET_LIST_VERIFIED_PAGE: "/v1/mitra/cms/mitra-verified-list",
  GET_LIST_DETAIL: "/mitra/cms/v1/",
  GET_DETAIL_V2: "/v2/mitra/",
  ACTION: "/v1/mitra",
  ACTION_APPROVAL: "/v1/mitra/update-status/",
  GET_LIST_BY_FO: "v1/mitra/cms/list-unassignment-mitra/",
  DEACTIVATE_MITRA: "mitra/cms/deactivated-mitra/",
  GET_CHECK_CAN_DEACTIVATE: "mitra/cms/check-mitra-can-deactivate/",
  GET_LIST_VERIF_COMBOBOX: "mitra/cms/list-mitra-for-deactivation",
  GET_TYPE_MITRA: "v1/mitra/type",
  CREATE_MITRA: "v2/mitra",
  UPDATE_IMAGE: "v1/mitra/cms/update-doc-image/",
  GET_LIST_MITRA_FOR_FILTER_ORDER: "v1/planting-season/order/list-mitra-filter/cms",
  GET_LIST_MITRA_FOR_FILTER_ORDER_PASCA_PLANTING: "v1/schedule/cms/filter/mitra",
  GET_LIST_MITRA_NON_KUR: "v1/mitra/cms/mitra-non-ca-list",
  GET_LIST_MITRA_KUR: "v1/mitra/cms/mitra-ca-list",
  GET_LIST_MITRA: (status) => `/cms/v1/mitra/list/${status}`,
  GET_LIST_MITRA_FOR_FILTER_PLANTING_SEASON: "cms/v1/area/planting-season-confirmation/filter/mitra"
}

export const FIELD_OFFICER_URL = {
  GET_TEAM_SCHEDULE: "field-officer/cms/v2/agronomist/team-scheduled",
  GET_CALENDAR_DETAIL_TEAM_DRONE: "field-officer/cms/v1/agronomist/team/detail/calendar/",
  GET_CALENDAR_DETAIL_TEAM_DRONE_LIST:
    "field-officer/cms/v1/agronomist/team/detail/calendar/activity/",
  GET_CALENDAR_DETAIL_TEAM_SCHEDULE: "field-officer/cms/v1/agronomist/team-scheduled/popup",
  GET_LIST_FOR_COMBOBOX: "/field-officer/cms/v1/all",
  GET_LIST: "/field-officer/cms/v1",
  ASSIGN_MITRA_TO_FO: "/field-officer/cms/v1/assign-mitra",
  ASSIGN_MITRA_TO_FO_BULK: "/field-officer/cms/v1/assign-mitra-bulk",
  ASSIGN_FO_TO_FO: "/field-officer/cms/v1/assign-field-officer",
  CREATE_FO: "field-officer/cms/v1",
  GET_DETAIL: (id) => `field-officer/cms/v1/${id}`,
  GET_LIST_BY_MITRA: "field-officer/cms/v1/list-field-officer/",
  GET_LIST_ASSIGN_MITRA: "field-officer/cms/v1/list-assignment-fo",
  DELETE_LIST_ASSIGN_MITRA: "field-officer/cms/v1/list-assignment-fo/",
  GET_CHECK_CAN_DEACTIVATE: "/v1/farmer/deactivated-field-officer/",
  DEACTIVATE_FIELD_OFFICER: "field-officer/cms/v1/deactivated-fo/",
  GET_LIST_ROLE_FO: "v1/role?param=fo",
  UPDATE_IMAGE: "field-officer/cms/v1/update-doc-image/",
  GET_LIST_AVAILABLE_MEMBER: "field-officer/cms/v1/agronomist/get-available-member",
  GET_LIST_AVAILABLE_MEMBER_EDIT: "field-officer/cms/v1/agronomist/get-available-member-edit/",
  CREATE_UPDATE_TEAM: "field-officer/cms/v1/agronomist/team/",
  GET_LIST_AGRO: "field-officer/cms/v1/agronomist/list",
  GET_LIST_HARVEST: "field-officer/cms/v1/harvest/list",
  GET_LIST_COORDINATOR: "field-officer/cms/v1/coordinator/list",
  GET_LIST_DRONE_TEAM: "field-officer/cms/v1/agronomist/team",
  GET_HEADER_DETAIL_TEAM_DRONE: "field-officer/cms/v1/agronomist/team/detail/header/",
  GET_LIST_QUALITY_ASSURANCE_ACTIVE: "cms/v1/field-officer/qa/active/list",
  GET_LIST_QUALITY_ASSURANCE_INACTIVE: "cms/v1/field-officer/qa/inactive/list"
}

export const FARMER_URL = {
  GET_LIST_ACTIVE_PAGE: "/farmer/cms/v2/status/active",
  GET_LIST_ACTIVE_ALL: "/v1/farmer/cms/farmer-registered-list",
  GET_LIST_ACTIVE_NON_KUR: "/v1/farmer/cms/farmer-non-kur-list",
  GET_LIST_ACTIVE_KUR: "/v1/farmer/cms/farmer-kur-list",
  GET_LIST_PENDING_PAGE: "/farmer/cms/v2/status/pending",
  GET_LIST_REJECTED_PAGE: "/farmer/cms/v2/status/rejected",
  GET_LIST_FARMER: "/v1/farmer/cms/all-has-activity",
  GET_LIST_DETAIL: "/v3/farmer/detail/cms/",
  GET_LIST_DETAIL_LEAD: "/cms/v1/lead-farmer/",
  GET_LIST_BY_MITRA: "/mitra/cms/v1/farmer/",
  CREATE_FARMER_V2: "/v2/farmer", //POST
  CREATE_FARMER_V2_BULK: "/v2/farmer/save/bulk", //POST BULK
  CREATE_FARMER_V2_BULK_LEAD: "/cms/v1/bulk-lead-farmer",
  GET_REJECT_REASON: "/farmer/cms/v2/reject/reason",
  GET_DOWNLOAD_EXCEL: "/file/farmer/download/template",
  UPDATE_STATUS: (id) => `/farmer/cms/v2/update-status/${id}`,
  GET_AREAS: (id) => `/area/cms/v1/get-all-area/${id}`,
  CREATE_CALON_FARMER: "/cms/v1/lead-farmer",
  GET_LIST_LEAD_PAGE: "/cms/v1/lead-farmer",
  REMOVE_LEAD_FARMER: "/cms/v1/lead-farmer/",
  GET_CHECK_CAN_DEACTIVATE: "/v1/farmer/check-farmer-can-deactivate/",
  DEACTIVATE_FARMER: "/v1/farmer/deactivated-farmer/",
  CHANGE_MITRA: "/v1/farmer/update-mitra/",
  UPDATE_IMAGE: "v1/farmer/update-doc-image/",
  GET_LIST_FARMER_FOR_FILTER_ORDER: "v1/planting-season/order/list-farmer-filter/cms",
  GET_LIST_FARMER_FOR_FILTER_ORDER_PASCA_PLANTING: "v1/schedule/cms/filter/farmer",
  GET_LIST_FARMER_PER_STATUS: (status) => `/cms/v1/farmer/list/${status}`
}

export const AREA_URL = {
  GET_LIST_ALL: "area/cms/v1",
  GET_LIST_MAP: "v2/area/cms/list-area",
  GET_LIST_MAP_NEW: "v2/maps/cms",
  GET_LIST_VERIFIED: "v2/area/cms/list/area-page",
  GET_LIST_KUR: "v1/area/cms/list/area-kur-list",
  GET_LIST_NON_KUR: "v1/area/cms/list/area-non-kur-list",
  GET_LIST_PENDING: "area/cms/v1/pending-register-request",
  GET_LIST_PENDING_UNASSIGN: "area/cms/v1/pending-unassign-request",
  GET_LIST_REJECTED: "area/cms/v1/area-reject",
  GET_LIST_DETAIL: "v2/area/",
  UPDATE_DATE_PLANT_AREA: "/v1/planting-event/cms/edit-plant-date",
  GET_DETAIL_PLANTING_EVENT_AREA: "/planting-event/cms/",
  GET_LIST_REJECT_REASON: "area/cms/v1/area-rejection-list",
  GET_LIST_LEAD: "v1/lead-area/cms",
  UPDATE_STATUS: "area/cms/v1/update-status/",
  UPDATE_STATUS_UNASSIGNED: "area/cms/v1/unassign-approval/",
  GET_LIST_LEAD_DETAIL: "v1/lead-area/cms/",
  GET_LIST_UNASSIGN: "area/cms/v1/area-unassign-list",
  UPDATE_UNASSIGN: "area/cms/v1/unassign-area",
  GET_LIST_UNASSIGN_DETAIL: "area/cms/v1/unassign/detail/",
  GET_LIST_UNASSIGNED: "area/cms/v1/unassign-area/list",
  UPDATE_IMAGE: "area/cms/v1/update-doc-image/",
  GET_LIST_ACTIVITY: "v1/planting-season/activity/active-list/cms/",
  GET_LIST_ACTIVITY_AREA: "v1/planting-season/activity/cms",
  PLANTING_SEASON_CONFIRMATION: "v1/planting-season/finish/",
  GET_HARVEST_ESTIMATION: "v2/planting-season/activity/harvest-prediction/cms/detail/",
  GET_HARVEST: "v2/planting-season/activity/harvest/cms/",
  GET_FERTILIZATION: "v2/planting-season/activity/fertilization/cms/",
  GET_PLANTING: "v2/planting-season/activity/planting/cms/",
  GET_SEEDLING: "v2/planting-season/activity/seedling/cms/",
  GET_SPRAYING: "v2/planting-season/activity/spraying/cms/",
  GET_HISTORY_ACTIVITY_LIST: "v1/planting-season/history/cms/",
  GET_HISTORY_PLANTING_SEASON_HEADER: "v1/planting-season/activity-history-header/cms/",
  GET_HISTORY_PLANTING_SEASON: "v1/planting-season/activity/activity-history/cms/",
  GET_TILLAGE: "v2/planting-season/activity/tillage/cms/",
  GET_FAILURE_HARVEST: "v1/planting-season/activity/harvest-failure/cms/",
  GET_ACTIVITY_LAND: "v1/planting-season/activity/activities-land/cms",
  GET_ACTIVITY_LAND_NON_KUR: "v1/planting-season/activity/activities-land-non-kur/cms",
  GET_ACTIVITY_LAND_LAND_KUR: "v1/planting-season/activity/activities-land-kur/cms",
  GET_ACTIVITY_WAITING: "v1/planting-season/activity/activities-land/cms/waiting",
  GET_ACTIVITY_APPROVED: "v1/planting-season/activity/activities-land/cms/approved",
  GET_ACTIVITY_MANUAL: "v1/planting-season/activity/activities-land/cms/manual",
  UPDATE_ACTIVITY_IMAGE: "v1/planting-season/activity/cms/v1/update-doc-image/",
  GET_PLANTING_STATUS: "area/cms/get-planting-status",
  GET_DETAIL_AREA_V3: "v3/area/cms/detail/",
  SEND_ACTIVITY_TO_ERP: "v2/planting-season/order/cms/confirmed/",
  APPROVE_ACTIVITY_BULK: "v1/planting-season/order/cms/confirmed-bulk",
  GET_DETAIL_MAP_AREA: "v3/area/cms/map/",
  GET_REASON_FAILED_HARVEST: "v1/planting-season/cms/failure-harvest-reason",
  GET_PEST_TYPE_LIST: "v1/pest_type",
  CREATE_FAILED_HARVEST: "v1/planting-season/cms/failure-harvest/",
  CANCEL_PLANTING_SEASON: "/v1/planting-season/cms/cancel/",
  CREATE_SO: "v1/planting-season/order-sales/cms/spk/create",
  LIST_ACTIVITY_READY_SEND_FINANCE: () => `/cms/v1/area/activity-report/ready-to-finance`,
  LIST_ACTIVITY_SENDING_FINANCE: () => `/cms/v1/area/activity-report/sent-to-finance`,
  LIST_ACTIVITY_NEED_BA: () => `/cms/v1/area/activity-report/need-ba`,
  LIST_ACTIVITY_NO_BILL: () => `cms/v1/area/activity-report/no-bills`,
  GET_LIST_READY_PLANTING_SEASON_CONFIRMATION: "cms/v1/area/planting-season-confirmation",
  BULK_CONFIRMATION_PLANTING_SEASON: () => "cms/v1/area/planting-season-confirmation",
  GET_ACTIVITY_LAND_BA_REPORT : () => `/cms/v1/report/ba/seedling/list`
}

export const AGENDA_URL = {
  GET_DETAIL_BY_LAHAN_ID: "planting-event/mobile/v1/detail/"
}

export const RIWAYAT_URL = {
  GET_RIWAYAT_BY_LAHAN_ID: "area/cms/v1/get-history-area/"
}

export const ROLE_URL = {
  GET_LIST: "/v1/role",
  GET_LIST_PRIVILEGE: "/v1/role/cms/privilege/",
  GET_LIST_PRIVILEGE_BY_ROLE: "v1/role/cms/privilege/",
  ASSIGN_PRIVILEGE_TO_ROLE: "v1/role/cms/set/role",
  GET_LIST_WITHOUT_FO: "/v1/role/cms/list"
}

export const ACCOUNT_URL = {
  GET_LIST_PAGE: "v1/account/account-access-cms",
  RESET_PASSWORD: "v1/account/generate-new-password",
  EDIT_PASSWORD: "account/change_password",
  CREATE_USER: "v1/account/register",
  EDIT_USER: "v1/account/cms/update-account/",
  GET_USER_DETAIL: "v1/account/account-access-cms/detail/",
  DEACTIVATE_ACCOUNT: "v1/account/account-access-cms/deactivation/"
}

export const VIDEO_URL = {
  GET_LIST_PAGE: "v1/tutorial/video/cms/list",
  CREATE_VIDEO: "v1/tutorial/video/cms/create",
  GET_LIST_DETAIL: "v1/tutorial/video/cms/detail/",
  UPDATE_VIDEO: "v1/tutorial/video/cms",
  REMOVE_VIDEO: "v1/tutorial/video/cms/"
}

export const BROADCAST_URL = {
  GET_LIST_BROADCAST: "inbox/list/cms",
  GET_DETAIL_BROADCAST: "inbox/broadcast/cms/",
  DELETE_BROADCAST: "inbox/list/cms/",
  GET_APP_LIST: "inbox/app-list/cms",
  CREATE_BROADCAST: "v1/inbox/info/broadcast/cms"
}

export const LOG_URL = {
  GET_LOG: "history/v1/"
}

export const ORDER_URL = {
  APPROVED_REJECT_ORDER: "v1/planting-season/order/approval/cms/",
  REJECT_ORDER_PACKAGE: "v1/planting-season/order/reject-order-package/cms/",
  EDIT_ORDER: "v1/planting-season/order/edit/cms/",
  RESCHEDULED_ORDER: "v1/planting-season/order/approval/rescheduled/cms/",
  GET_LIST_ALL: "v1/planting-season/order/list-all/cms/",
  GET_LIST_APPROVED: "v1/planting-season/order/list-approved/cms/",
  GET_LIST_PENDING: "v1/planting-season/order/list-pending/cms/",
  GET_LIST_PENDING_PRA_TANAM: "v1/planting-season/order/list-pending-preplanting/cms/",
  GET_LIST_RESCHEDULED: "v1/planting-season/order/list-rescheduled/cms/",
  GET_STATUS_FILTER: "v1/planting-season/order/list-status-filter/cms",
  GET_DETAIL_ORDER: "v1/planting-season/order/detail/cms/",
  GET_REJECT_REASON_LIST: "v1/planting-season/order/rejection-list/cms",
  CANCEL_ENUM_LIST: "v1/planting-season/order/cancellation-list/cms",
  CANCEL_ORDER: "v1/planting-season/order/cancel/cms/",
  GET_DETAIL_HEADER: "v1/planting-season/order/approval/detail/header/cms/",
  GET_DETAIL_HEADER_APPROVAL: "v1/planting-season/order/approval/detail/reschedule/cms/",
  GET_DETAIL_EDIT_ORDER: "v1/planting-season/order/approval/detail/edit/cms/",
  GET_REGION_ORDER: "v1/planting-season/order/regional-list/cms",
  GET_DRONE_TEAM: "v1/planting-season/order/team-list/cms",
  GET_CALENDAR_STATUS: "v2/planting-season/order/calendar-list/cms/",
  GET_LIST_DATA_APPROVE_BULK: "v1/planting-season/order/init-bulk-approval-spraying/cms",
  GET_LIST_DATA_APPROVE_BULK_PRE_PLANTING:
    "v1/planting-season/order/init-bulk-approval-spraying-preplanting/cms",
  APPROVE_BULK: "v1/planting-season/order/approval-bulk/cms",
  APPROVE_BULK_PRE_PLANTING: "v1/planting-season/order/approval-bulk-preplanting/cms",
  GET_DETAIL_SPK: (id) => "v1/planting-season/order-sales/cms/detail/spk/" + id,
  GET_DETAIL_SO: (id) => "v1/planting-season/order-sales/cms/detail/so/" + id,
  GET_LIST_SO: "v1/planting-season/order/cms/list/so",
  GET_LIST_SPK: "/v1/plating-season/order-sales/cms/list/spk",
  UPLOAD_SO_XLS: "/v1/plating-season/order-sales/cms/so/upload",
  DOWNLOAD_SO_XLS: "/v1/plating-season/order-sales/cms/so/template",
  GET_LIST_PASCA_PLANTING: (status) => `v1/schedule/cms/list/${status}`,
  GET_LIST_PRE_PLANTING: (status) => `v1/schedule/cms/list/pre-planting/${status}`,
  GET_SUMMARY_CARD_SPRAYING: () => `v1/schedule/cms/summary`,
  GET_DETAIL_ORDER_PASCA_PLANTING: (id) => `v1/schedule/cms/detail/${id}`,
  GET_SUMMARY_CARD_SPRAYING_PRE_PLANTING: () => `v1/schedule/cms/summary/pre-planting`
}

export const MAC_URL = {
  GET_LIST_MERCHANT: () => `v1/external-partner/cms/list`,
  GET_DETAIL_MERCHANT: (id) => `v1/external-partner/cms/detail/${id}`,
  GET_LIST_USER: () => `v1/external-partner/cms/user/list`,
  GET_DETAIL_USER: (id) => `v1/external-partner/cms/user/detail/${id}`,
  GET_SUMMARY_CARD_SPRAYING: () => `v1/external-partner/cms/schedule/counter`,
  LIST_SCHEDULING_MAC: (status) => `v1/external-partner/cms/schedule/list/${status}`,
  GET_ACTIVITY_REPORT_MAC: () => `v1/external-partner/cms/activity/report-list`,
  GET_DETAIL_SPRAYING: (id) => `v1/external-partner/cms/schedule/detail/${id}`,
  GET_DETAIL_ACTIVITY_REPORT_MAC: (id) => `/v1/external-partner/cms/report/activity-detail/${id}`,
  APPROVED_SPRAYING: (id) => `v1/external-partner/cms/schedule/approval/${id}`,
  EDIT_SPRAYING: (id) => `v1/external-partner/cms/schedule/edit/${id}`,
  RESCHEDULE_SPRAYING: (id) => `v1/external-partner/cms/schedule/reschedule/cms/${id}`,
  REJECT_SPRAYING: (id) => `v1/external-partner/cms/schedule/reject/${id}`,
  CANCEL_SPRAYING: (id) => `v1/external-partner/cms/schedule/cancel/${id}`
}

export const SUBMISSION_URL = {
  GET_LIST_CERTIFICATION: (status) => `cms/v1/certification/list/${status}`,
  GET_LIST_MITRA: () => `cms/v1/certification/list/filter/mitra`,
  GET_LIST_SUBDISTRICT: () => `cms/v1/certification/list/filter/subdistrict`,
  GET_LIST_VARIETAS: () => `cms/v1/certification/list/filter/variety`,
  GET_LIST_AREA: () => `cms/v1/certification/list/area`,
  GET_LIST_AREA_MAP: () => `cms/v1/certification/list/area-map`,
  GET_INIT_CREATE_CERTIFICATION: () => `cms/v1/certification/init`,
  GET_SUMMARY_DATA: () => `cms/v1/certification/summary`,
  GET_DETAIL_CERTIFICATION_AREA: (id) => `cms/v1/certification/detail/area/${id}`,
  GET_LIST_SEED_CLASS: () => "cms/v1/certification/list/seed-class",
  CREATE_SUBMISSION: () => "cms/v1/certification/create",
  CANCEL_SUBMISSION: () => "cms/v1/certification/cancel",
  GET_DETAIL_CERTIFICATION_BLOCK: (id) => `cms/v1/certification/detail/block/${id}`,
  GET_DETAIL_DOWNLOAD_SUBMISSION: () => `cms/v1/certification/file-download`
}